import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/offerings",
    name: "offerings",
    component: () =>
      import(
        /* webpackChunkName: "offerings" */ '@/views/offerings.vue'
      )
  },
  {
    path: "/cfd",
    name: "cfd",
    component: () =>
      import(
        /* webpackChunkName: "cfd" */ '@/views/cfd.vue'
      )
  },
  {
    path: "/shares",
    name: "shares",
    component: () =>
      import(
        /* webpackChunkName: "shares" */ '@/views/shares.vue'
      )
  },
  {
    path: "/spread-betting",
    name: "spread-betting",
    component: () =>
      import(
        /* webpackChunkName: "spread-betting" */ '@/views/spread-betting.vue'
      )
  },
  {
    path: "/forex",
    name: "forex",
    component: () =>
      import(
        /* webpackChunkName: "forex" */ '@/views/forex.vue'
      )
  },
  {
    path: "/commodities",
    name: "commodities",
    component: () =>
      import(
        /* webpackChunkName: "commodities" */ '@/views/commodities.vue'
      )
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () =>
      import(
        /* webpackChunkName: "contactus" */ '@/views/contactus.vue'
      )
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ '@/views/contact.vue'
      )
  },
  {
    path: "/our-firm",
    name: "our-firm",
    component: () =>
      import(
        /* webpackChunkName: "our-firm" */ '@/views/our-firm.vue'
      )
  },
  {
    path: "/workshops",
    name: "workshops",
    component: () =>
      import(
        /* webpackChunkName: "workshops" */ '@/views/workshops.vue'
      )
  },
  {
    path: "/research",
    name: "research",
    component: () =>
      import(
        /* webpackChunkName: "research" */ '@/views/research.vue'
      )
  },
  {
    path: "/rsvp",
    name: "rsvp",
    component: () =>
      import(
        /* webpackChunkName: "rsvp" */ '@/views/rsvp.vue'
      )
  },
  {
    path: "/opening-hours",
    name: "opening-hours",
    component: () =>
      import(
        /* webpackChunkName: "opening-hours" */ '@/views/opening-hours.vue'
      )
  },
  {
    path: "/message-from-the-md",
    name: "message-from-the-md",
    component: () =>
      import(
        /* webpackChunkName: "message-from-the-md" */ '@/views/message-from-the-md.vue'
      )
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(
        /* webpackChunkName: "about-us" */ '@/views/about-us.vue'
      )
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ '@/views/privacy-policy.vue'
      )
  },
  {
    path: "/terms-agreements",
    name: "terms-agreements",
    component: () =>
      import(
        /* webpackChunkName: "terms-agreements" */ '@/views/terms-agreements.vue'
      )
  },
  {
    path: "/personal",
    name: "personal",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ '@/views/personal.vue'
      )
  },
  {
    path: "/result",
    name: "result",
    component: () =>
      import(
        /* webpackChunkName: "personal" */ '@/views/result.vue'
      )
  },
];
const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
