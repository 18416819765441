<template>
  <div class="show-on-scroll-wrapper" id="showOnScrollWrapper">
    <header id="header" class="show-on-scroll" data-offset-el=".index-section" data-offset-behavior="bottom"
      role="banner">
      <div class="header-inner">
        <div id="logoWrapper" class="wrapper" data-content-field="site-title">

          <h1 id="logoImage"><a href="/"><img
                src="@/assets/logo.png"
                alt="EquiAssets"></a></h1>

        </div><!--
      -->
        <div class="mobile-nav-toggle">
          <div class="top-bar"></div>
          <div class="middle-bar"></div>
          <div class="bottom-bar"></div>
        </div>
        <div class="mobile-nav-toggle fixed-nav-toggle">
          <div class="top-bar"></div>
          <div class="middle-bar"></div>
          <div class="bottom-bar"></div>
        </div><!--
      -->
        <div id="headerNav">

          <div id="mainNavWrapper" class="nav-wrapper" data-content-field="navigation-mainNav">
            <nav id="mainNavigation" data-content-field="navigation-mainNav">





              <div class="index homepage" :class="{active:path=='/'}">
                <a href="/">
                  Home
                </a>
              </div>








              <div class="folder" :class="{active:['/offerings','/cfd','/shares','/spread-betting','/forex','/commodities'].includes(path)}">
                <div class="folder-toggle" data-href="/invest-1">Invest</div>
                <div class="subnav">



                  <div class="collection" :class="{active:path=='/offerings'}">
                    <a href="/offerings">
                      Our Offerings
                    </a>
                  </div>





                  <div class="collection" :class="{active:path=='/cfd'}">
                    <a href="/cfd">
                      CFD
                    </a>
                  </div>





                  <div class="collection" :class="{active:path=='/shares'}">
                    <a href="/shares">
                      Shares
                    </a>
                  </div>





                  <div class="collection" :class="{active:path=='/spread-betting'}">
                    <a href="/spread-betting">
                      Spread Betting
                    </a>
                  </div>





                  <div class="collection" :class="{active:path=='/forex'}">
                    <a href="/forex">
                      Forex
                    </a>
                  </div>





                  <div class="collection" :class="{active:path=='/commodities'}">
                    <a href="/commodities">
                      Commodities
                    </a>
                  </div>



                </div>
              </div>









              <div class="folder" :class="{active:path=='/our-firm'}">
                <div class="folder-toggle" data-href="/our-firm-1">Our Firm</div>
                <div class="subnav">




                  <div class="external">
                    <a href="/our-firm#message-from-the-md">
                      Message from the MD
                    </a>
                  </div>





                  <div class="external">
                    <a href="/our-firm#about-us">
                      About Us
                    </a>
                  </div>


                </div>
              </div>











              <div class="collection" :class="{active:path=='/workshops'}">
                <a href="/workshops">
                  Workshops
                </a>
              </div>













              <div class="collection" :class="{active:path=='/research'}">
                <a href="/research">
                  Research
                </a>
              </div>









              <div class="index" :class="{active:path=='/contactus'}">
                <a href="/contactus">
                  Contact Us
                </a>
              </div>










              <div class="collection">
                <a href="/rsvp">
                  Open an Account
                </a>
              </div>







            </nav>
          </div>


        </div>
      </div>
    </header>
  </div>
  <header id="header" class="show-on-scroll" data-offset-el=".index-section" data-offset-behavior="bottom"
    role="banner">
    <div class="header-inner">
      <div id="logoWrapper" class="wrapper" data-content-field="site-title">

        <h1 id="logoImage"><a href="/"><img
              src="@/assets/logo.png"
              alt="EquiAssets" /></a></h1>

      </div>
      <!--
          -->
      <div class="mobile-nav-toggle" @click="changeMobileNav">
        <div class="top-bar"></div>
        <div class="middle-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <div class="mobile-nav-toggle fixed-nav-toggle">
        <div class="top-bar"></div>
        <div class="middle-bar"></div>
        <div class="bottom-bar"></div>
      </div>
      <!--
          -->
      <div id="headerNav">

        <div id="mainNavWrapper" class="nav-wrapper" data-content-field="navigation-mainNav">
          <nav id="mainNavigation" data-content-field="navigation-mainNav">





            <div class="index homepage" :class="{active:path=='/'}">
              <a href="/">
                Home
              </a>
            </div>








            <div class="folder" :class="{active:['/offerings','/cfd','/shares','/spread-betting','/forex','/commodities'].includes(path)}">
              <div class="folder-toggle" data-href="/invest-1">Invest</div>
              <div class="subnav">



                <div class="collection" :class="{active:path=='/offerings'}">
                  <a href="/offerings">
                    Our Offerings
                  </a>
                </div>





                <div class="collection" :class="{active:path=='/cfd'}">
                  <a href="/cfd">
                    CFD
                  </a>
                </div>





                <div class="collection" :class="{active:path=='/shares'}">
                  <a href="/shares">
                    Shares
                  </a>
                </div>





                <div class="collection" :class="{active:path=='/spread-betting'}">
                  <a href="/spread-betting">
                    Spread Betting
                  </a>
                </div>





                <div class="collection" :class="{active:path=='/forex'}">
                  <a href="/forex">
                    Forex
                  </a>
                </div>





                <div class="collection" :class="{active:path=='/commodities'}">
                  <a href="/commodities">
                    Commodities
                  </a>
                </div>



              </div>
            </div>









            <div class="folder" :class="{active:path=='/our-firm'}">
              <div class="folder-toggle" data-href="/our-firm-1">Our Firm</div>
              <div class="subnav">




                <div class="external">
                  <a href="/our-firm#message-from-the-md">
                    Message from the MD
                  </a>
                </div>





                <div class="external">
                  <a href="/our-firm#about-us">
                    About Us
                  </a>
                </div>


              </div>
            </div>











            <div class="collection" :class="{active:path=='/workshops'}">
              <a href="/workshops">
                Workshops
              </a>
            </div>













            <div class="collection" :class="{active:path=='/research'}">
              <a href="/research">
                Research
              </a>
            </div>









            <div class="index" :class="{active:path=='/contactus'}">
              <a href="/contactus">
                Contact Us
              </a>
            </div>










            <div class="collection">
              <a href="/rsvp">
                Open an Account
              </a>
            </div>







          </nav>
        </div>


      </div>
    </div>
  </header>
  
  <router-view />  
</template>

<script setup>
  import { ref, onMounted, onUnmounted } from "vue";
  import { useRouter } from "vue-router"
import { useMessage } from "naive-ui";
  const navShowPosition = ref(120)
  const scrolling = ref(false)
  const path = ref()
  window.$message = useMessage()
  onMounted(() => {
    const router = useRouter();
    setTimeout(()=>{
      path.value = router.currentRoute.value.path
    },500)
    window.onload = ()=>{
      if(window.innerWidth <= 640) return;
      const el = $('.show-on-scroll');
      const elOffset = el.data('offset-el');
      const offsetBehavior = (el.data("offset-behavior") || "top");
      const wrapper = $('#showOnScrollWrapper');
      wrapper.setHTML = el[0].outerHTML;
      window.onscroll = ()=>{
        if(scrolling.value === false && $(elOffset).length > 1){
          scrolling.value = true;
          if(window.pageYOffset > navShowPosition.value){
            wrapper.addClass('show')
          } else {
            wrapper.removeClass('show')
          }
          scrolling.value = false
        }
      }
      const syncUI = ()=>{
        if($(elOffset).length > 1){
          if('bottom' === offsetBehavior){
            navShowPosition.value = $(elOffset+':nth-of-type(2)').offset().top
          } else {
            navShowPosition.value = $(elOffset+':nth-of-type(2)').offset().top
          }
        }
      }
      syncUI()
      window.onresize = syncUI();
    }
  });
  onUnmounted(()=>{    
    if(window.innerWidth <= 640) return;
    window.onscroll = ()=>{}
    window.onresize = ()=>{}
  })

  const changeMobileNav = ()=>{
    $('body').toggleClass('mobile-nav-open')
  }
</script>

<style lang="scss" scoped>
  .mobile-nav-toggle-label {
    display: inline-block !important;
  }
</style>