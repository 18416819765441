
<template>
  <div v-if="show" class="yui-popup-container-node" id="yui_3_17_2_1_1717395913424_400">
    <div
      id="yui_3_17_2_1_1717395913424_257"
      class="yui3-widget sqs-widget sqs-popup-overlay"
      style="display: block"
      aria-labelledby="sqs-slash-page-header"
      role="dialog"
    >
      <div
        id="yui_3_17_2_1_1717395913424_259"
        class="sqs-popup-overlay-content"
      >
        <div
          class="sqs-slide-wrapper"
          data-slide-id="5b56fe091ae6cf85cd421e7a"
          data-slide-type="popup-overlay"
          id="yui_3_17_2_1_1717395913424_399"
        >
          <div
            class="sqs-slide-container countdown-numeric-label-font-size-medium page-border-size-medium video-overlay-color-blend-mode-normal button-layout-style-inline-auto button-style-solid button-shape-rectangle social-icons-size-small social-icons-style-knockout social-icons-shape-circle audio-player-icons-size-small audio-player-icons-style-border audio-player-icons-shape-circle video-icon-size-large video-icon-style-knockout video-icon-shape-circle map-style-minimal-light lock-size-small lock-style-regular lock-shape-circle password-style-rectangle newsletter-layout-style-stacked newsletter-style-outline overlay-close-button-style-icon overlay-close-button-location-inside overlay-load-animation-slide-in overlay-advanced-layout-styles-disabled overlay-alignment-left overlay-mobile-styles-enabled sso-button-style-outline sso-button-shape-rectangle"
            data-slide-id="5b56fe091ae6cf85cd421e7a"
            data-slide-type="popup-overlay"
            id="yui_3_17_2_1_1717395913424_398"
          >
            <div
              class="sqs-slide yui3-widget yui3-overlaycornercard01 yui3-overlaycornercard01-content sqs-slide-ready sqs-slide-animation-ready"
              data-slide-id="5b56fe091ae6cf85cd421e7a"
              data-slide-layout-id="overlay-corner-card-01"
              data-slide-type="popup-overlay"
              id="yui_3_17_2_1_1717395913424_300"
            >
              <div class="sqs-slide-layer layer-back full-width-height"></div>

              <div
                class="sqs-slide-layer layer-front full-width-height"
                id="yui_3_17_2_1_1717395913424_387"
              >
                <div
                  class="sqs-slide-layer-content"
                  data-dynamic-strings=""
                  id="yui_3_17_2_1_1717395913424_386"
                >
                  <a
                    class="sqs-popup-overlay-close"
                    role="button"
                    tabindex="0"
                    aria-label="Close"
                    id="yui_3_17_2_1_1717395913424_385"
                    @click="hide"
                    
                  ></a>

                  <div
                    class="sqs-slice-group group-copy copy-layer-background"
                    id="yui_3_17_2_1_1717395913424_396"
                  >
                    <div
                      class="sqs-slice yui3-widget sqs-slice-heading sqs-slice-heading-content sqs-slice-heading-focused"
                      data-slice-type="heading"
                      data-slice-id="631707c4c5e6bd76b1acb6ef"
                      id="yui_3_17_2_1_1717395913424_326"
                    >
                      <h1
                        id="sqs-slash-page-header"
                        style="display: inline-block"
                      >
                        Risk Warning
                      </h1>
                    </div>
                    <div
                      class="sqs-slice yui3-widget sqs-slice-body sqs-slice-body-content"
                      data-slice-type="body"
                      data-slice-id="631707c4c5e6bd76b1acb6f0"
                      id="yui_3_17_2_1_1717395913424_348"
                    >
                      <p class="">
                        Spread bets and CFDs are complex instruments and come
                        with a high risk of losing money rapidly due to
                        leverage. 76% of retail investor accounts lose money
                        when trading spread bets and CFDs with this provider.
                        You should consider whether you understand how spread
                        bets and CFDs work, and whether you can afford to take
                        the high risk of losing your money.
                      </p>
                      <p
                        class=""
                        data-rte-preserve-empty="true"
                       
                      ></p>
                      <p class="">
                        We use cookies to help us improve, promote, and protect
                        our services. By clicking the OK button, you agree to
                        our
                        <a
                          href="https://static1.squarespace.com/static/59117d621b10e3264874a060/t/5a2fad3553450aa142a15d63/1513073977937/Cookie-Policy.pdf"
                          target="_blank"
                          >cookie policy</a
                        >.
                      </p>
                    </div>
                    <div class="actions text-align-center">
                      <div
                        class="sqs-slice"
                        data-slice-type="buttons"
                        data-compound-type="popup-overlay-action"
                        data-slice-id="63170798f826c84ff2a44a6e"
                        data-content-empty="true"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,onMounted } from "vue";

const show = ref(localStorage.getItem('read')!=1)
localStorage.setItem('read',1)
const hide = ()=>{
  show.value = false;
}
onMounted(()=>{
  setTimeout(() => {
    if(show.value){
      $('#yui_3_17_2_1_1717395913424_257').addClass('visible');
    }
  }, 500);
})
</script>

<style lang="scss">
.sqs-popup-overlay {
  background: transparent;
  display: block;
  height: 100%;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.25s ease-out;
  width: 100%;
  z-index: 11000;
}

.sqs-popup-overlay:not(.visible) {
  opacity: 0;
  pointer-events: none;
}

.sqs-popup-overlay .sqs-slide-layer.layer-front {
  pointer-events: none;
}

.sqs-popup-overlay .sqs-slide-layer.layer-front .sqs-slide-layer-content {
  pointer-events: all;
}

@media (max-width: 600px) {
  .sqs-popup-overlay {
    bottom: 0;
    height: 50vh;
    top: auto;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  to {
    opacity: 0;
    transform: scale(0.3);
  }
}

@media screen and (max-width: 432px) {
  .mobile-hidden,
  .subDesktop-hidden {
    display: none;
  }
}

@media screen and (min-width: 433px) {
  .desktop-hidden {
    display: none;
  }
}

.sqs-slide {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-rendering: optimizeLegibility;
  top: 0;
  width: 100%;
}

html.browser-safari .sqs-slide {
  -webkit-font-smoothing: antialiased;
}

.sqs-slide.sqs-slide-ready {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.33, 0, 0.2, 1);
}

.captcha-container.rendered {
  margin-bottom: 24px;
  width: 304px;
}

.captcha-container.rendered.align-center {
  margin-left: auto;
  margin-right: auto;
}

.captcha-container.rendered.align-right {
  margin-left: auto;
}

.sqs-async-form-content .field-error {
  background: #cc3b3b
    url(//assets.squarespace.com/universal/images-v6/standard/icon_close_7_light.png)
    no-repeat 9px 50%;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  line-height: 23px;
  margin: 12px 0;
  padding: 5px 15px 3px 25px;
}

.sqs-async-form-content .form-wrapper .field-error:focus:not(:focus-visible),
.sqs-async-form-content
  .form-wrapper
  .form-submission-html:focus:not(:focus-visible),
.sqs-async-form-content
  .form-wrapper
  .form-submission-text:focus:not(:focus-visible) {
  outline: none;
}

.sqs-async-form-content .form-wrapper .field-list {
  line-height: normal;
}

.sqs-async-form-content .form-wrapper .field-list fieldset,
.sqs-async-form-content .form-wrapper .field-list legend {
  border: 0;
  margin: 0;
  padding: 0;
}

.sqs-async-form-content .form-wrapper .field-list textarea {
  min-height: 100px;
  resize: vertical;
}

.sqs-async-form-content .form-wrapper .field-list textarea.medium {
  min-height: 200px;
}

.sqs-async-form-content .form-wrapper .field-list textarea.large {
  min-height: 300px;
}

.sqs-async-form-content .form-wrapper .field-list .section {
  font-size: 0.9em;
  margin: 2em 0;
  padding-bottom: 0.3em;
}

.sqs-async-form-content .form-wrapper .field-list .section.underline {
  border-bottom: 1px solid #999;
}

.sqs-async-form-content .form-wrapper .field-list .section:first-child {
  margin: 0 0 2em;
}

.sqs-async-form-content .form-wrapper .field-list .title {
  display: block;
}

.sqs-async-form-content .form-wrapper .field-list .description {
  display: block;
  font-size: 12px;
  opacity: 0.7;
  padding: 0.5em 0;
  white-space: pre-wrap;
}

.sqs-async-form-content .form-wrapper .field-list .field {
  margin: 0 0 24px;
  position: relative;
}

.sqs-async-form-content .form-wrapper .field-list .field .caption {
  font-size: 12px;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .field
  .caption
  .field-element {
  font-size: 14px;
}

.sqs-async-form-content .form-wrapper .field-list .field .field-element {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #000;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  margin: 6px 0 4px;
  padding: 12px;
  width: 100%;
}

.sqs-async-form-content .form-wrapper .field-list .field .field-element:focus {
  background: #fff;
  transition: background 0.1s ease-in;
}

.sqs-async-form-content .form-wrapper .field-list .field select {
  margin: 6px 0 4px;
  max-width: 100%;
}

.sqs-async-form-content .form-wrapper .field-list .field .prefix {
  bottom: 16px;
  color: #aaa;
  font-family: sans-serif;
  font-size: 13px;
  left: 8px;
  line-height: 16px;
  position: absolute;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .field.twitter
  .field-element {
  padding-left: 22px;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .field.currency.hassymbol
  .field-element {
  padding-left: 20px;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .field.website
  .field-element {
  padding-left: 45px;
}

.sqs-async-form-content .form-wrapper .field-list .field.checkbox label,
.sqs-async-form-content .form-wrapper .field-list .field.radio label {
  cursor: pointer;
}

.sqs-async-form-content .form-wrapper .field-list .field.checkbox input,
.sqs-async-form-content .form-wrapper .field-list .field.radio input {
  margin-right: 5px;
}

.sqs-async-form-content .form-wrapper .field-list .field .option {
  font-size: 13px;
  margin: 6px 0 4px;
}

.sqs-async-form-content .form-wrapper .field-list .field.likert .item {
  margin: 1.6em 0;
}

.sqs-async-form-content .form-wrapper .field-list .field.likert .question {
  font-size: 0.9em;
  margin: 0 0 0.5em;
}

.sqs-async-form-content .form-wrapper .field-list .field.likert .option {
  border-top: 1px solid #ddd;
  float: left;
  text-align: left;
  width: 20%;
}

.sqs-async-form-content .form-wrapper .field-list .field.likert .option label {
  cursor: pointer;
  display: block;
  font-size: 0.9em;
  margin: 0;
  padding: 0 0 0 1px;
}

.sqs-async-form-content .form-wrapper .field-list .field.likert .option input {
  display: block;
  margin: 10px 0;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .field.likert
  .option:last-of-type {
  border-right: none;
}

.sqs-async-form-content .form-wrapper .field-list .fields {
  margin: 0 0 0 -2%;
}

.sqs-async-form-content .form-wrapper .field-list .fields .description,
.sqs-async-form-content .form-wrapper .field-list .fields .field,
.sqs-async-form-content .form-wrapper .field-list .fields .field-error,
.sqs-async-form-content .form-wrapper .field-list .fields .title {
  margin-left: 2%;
}

.sqs-async-form-content .form-wrapper .field-list .fields .field {
  float: left;
}

.sqs-async-form-content .form-wrapper .field-list .fields .field.two-digits {
  width: 3.5em;
}

.sqs-async-form-content .form-wrapper .field-list .fields .field.three-digits {
  width: 4.2em;
}

.sqs-async-form-content .form-wrapper .field-list .fields .field.four-digits {
  width: 4.8em;
}

.sqs-async-form-content .form-wrapper .field-list .fields .field.ampm {
  width: 4.5em;
}

.sqs-async-form-content .form-wrapper .field-list .fields.name .field {
  width: 48%;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .fields.address
  .field.address1,
.sqs-async-form-content
  .form-wrapper
  .field-list
  .fields.address
  .field.address2 {
  width: 98%;
}

.sqs-async-form-content .form-wrapper .field-list .fields.address .field.city {
  width: 70%;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .fields.address
  .field.state-province {
  width: 26%;
}

.sqs-async-form-content .form-wrapper .field-list .fields.address .field.zip {
  width: 36%;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .fields.address
  .field.country {
  width: 98%;
}

.sqs-async-form-content
  .form-wrapper
  .field-list
  .fields.payment
  .field.card-expiry-month,
.sqs-async-form-content
  .form-wrapper
  .field-list
  .fields.payment
  .field.card-expiry-year {
  width: 40%;
}

.sqs-async-form-content .form-wrapper .field-list .form-item.error,
.sqs-async-form-content .form-wrapper .field-list .form-item.error .caption,
.sqs-async-form-content .form-wrapper .field-list .form-item.error .description,
.sqs-async-form-content .form-wrapper .field-list .form-item.error .title {
  color: #bd0000;
}

.sqs-async-form-content .form-wrapper .field-list .form-item.error input,
.sqs-async-form-content .form-wrapper .field-list .form-item.error textarea {
  border: 1px solid #e99292;
}

.sqs-async-form-content .form-wrapper .form-button-wrapper--align-left {
  text-align: left;
}

.sqs-async-form-content .form-wrapper .form-button-wrapper--align-center {
  text-align: center;
}

.sqs-async-form-content .form-wrapper .form-button-wrapper--align-right {
  text-align: right;
}

.sqs-async-form-content .form-wrapper input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #272727;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0;
  line-height: 1em;
  padding: 1em 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-transform: none;
  width: auto;
}

.sqs-async-form-content .form-wrapper .form-submission-text {
  margin-top: 20px;
}

.sqs-async-form-content .form-wrapper .field .field-error {
  margin-bottom: 0.5em;
}

.sqs-async-form-content .form-wrapper .submitting .field-list {
  opacity: 0.7;
}

.sqs-async-form-content .form-wrapper .hidden,
.sqs-async-form-content .form-wrapper.hidden {
  display: none;
}

.sqs-async-form-content.saving {
  opacity: 0.5;
}

.sqs-slide-wrapper[data-slide-type="popup-overlay"] .text-align-center {
  text-align: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .responsive-wrapper {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper
  .sqs-slice {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper
  .sqs-slice[data-content-empty="true"] {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper
  .sqs-slice:not([data-content-empty="true"])
  ~ .sqs-slice:not([data-content-empty="true"]) {
  margin-left: 1em;
}
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper:not(.stacked)
  .sqs-slice:not([data-content-empty="true"]):not(:last-child),
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper:not(.stacked)
  .sqs-slice:not([data-content-empty="true"])
  + .sqs-slice:not([data-content-empty="true"]):not(:last-child) {
  margin-left: 0;
  margin-right: 1em;
}
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper:not(.stacked)
  .sqs-slice:not([data-content-empty="true"])
  + .sqs-slice:not([data-content-empty="true"]):last-child {
  margin-left: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice:not(:last-child):not([data-content-empty="true"]) {
  margin-bottom: 15px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice[data-content-empty="true"] {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice:not([data-content-empty="true"])
  ~ .sqs-slice:not([data-content-empty="true"]),
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="social-icons"]
  + [data-compound-type="action"][data-content-empty="true"]
  + [data-slice-type="custom-form"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-compound-type="action"]:not([data-content-empty="true"])
  + [data-slice-type="custom-form"][data-content-empty="true"]
  + [data-slice-type="social-icons"] {
  margin-left: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="buttons"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="sso-options"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="navigation"]
  ul {
  display: block;
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="buttons"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="sso-options"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="navigation"]
  ul
  li {
  display: block;
  margin: 0;
  width: 100%;
  max-width: 280px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="buttons"]
  ul
  li:not(:last-child),
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="sso-options"]
  ul
  li:not(:last-child),
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="navigation"]
  ul
  li:not(:last-child) {
  margin-bottom: 15px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="buttons"]
  ul
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="sso-options"]
  ul
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="navigation"]
  ul
  li
  a {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="navigation"]
  ul
  li
  a {
  padding-left: 0;
  padding-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice-custom-form {
  display: block;
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice-custom-form
  span {
  display: block;
  margin: 0;
  width: 100%;
  max-width: 280px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice-custom-form
  span:not(:last-child) {
  margin-bottom: 15px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice-custom-form
  span
  a {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  [data-slice-type="sso-options"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked
  .sqs-slice-custom-form
  a {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked.text-align-center
  [data-slice-type="buttons"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked.text-align-center
  [data-slice-type="sso-options"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked.text-align-center
  [data-slice-type="navigation"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.stacked.text-align-center
  .sqs-slice-custom-form
  span {
  margin-left: auto;
  margin-right: auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .icon-wrapper {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  transform: translatez(0);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .icon-wrapper > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .icon-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .scrim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container:not(.auto-overlay)
  .scrim {
  opacity: 1 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-licensed-asset-preview-bar {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 88px;
  background-color: #3e3e3e;
  color: #fff;
  padding: 11px;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-licensed-asset-preview-bar-content {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-licensed-asset-preview-bar-content
  span {
  font-family: helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 11px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .flex-grow-auto {
  flex: 1 0 auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .flex-grow-one {
  flex: 1 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .flex-grow-two {
  flex: 2 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .flex-shrink-full {
  flex: 0 1 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .flex-none {
  flex: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slide-layer {
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer.full-width-height {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer.full-width-height
  > .sqs-slide-layer-content {
  height: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer.split-fifty::after,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer.split-fifty::before {
  content: " ";
  display: table;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer.split-fifty::after {
  clear: both;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer.split-fifty
  > .sqs-slide-layer-content {
  float: left;
  width: 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slide-layer.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slide-layer-content {
  position: relative;
  padding: 40px 60px 60px 60px;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-layer-content.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice {
  position: relative;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice[data-content-empty="true"] {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-group {
  position: relative;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.align-center-horiz {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.align-center-vert {
  position: relative;
  top: 50%;
  box-sizing: border-box;
  transform: translatey(-50%);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.align-center {
  position: relative;
  top: 50%;
  left: 50%;
  padding: 60px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.align-center-absolute {
  position: absolute;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.align-center-js {
  position: absolute;
  top: 50%;
  left: 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.align-center-vert-js {
  position: relative;
  top: 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-group.bottom {
  position: absolute;
  bottom: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.full-width {
  position: absolute;
  left: 60px;
  right: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.bottom-full-width {
  position: absolute;
  bottom: 60px;
  left: 60px;
  right: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.top-full-width {
  position: absolute;
  top: 40px;
  left: 60px;
  right: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-group.inline {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.inline
  > * {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right::after,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right::before {
  content: " ";
  display: table;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right::after {
  clear: both;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right
  > *:first-child {
  float: left;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right
  > *:last-child {
  float: right;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right-floats-off
  > *:first-child {
  float: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right-floats-off
  > *:last-child {
  float: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left::after,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left::before {
  content: " ";
  display: table;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left::after {
  clear: both;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left
  > *:first-child {
  float: right;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left
  > *:last-child {
  float: left;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left-floats-off
  > *:first-child {
  float: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left-floats-off
  > *:last-child {
  float: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right-flex
  > *:first-child {
  flex: 1 1 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right-flex
  > *:last-child {
  flex: 1 1 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.inline
  [data-slice-type="buttons"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right
  [data-slice-type="buttons"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left
  [data-slice-type="buttons"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.inline
  [data-slice-type="sso-options"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right
  [data-slice-type="sso-options"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left
  [data-slice-type="sso-options"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.inline
  .sqs-slice-custom-form,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.left-right
  .sqs-slice-custom-form,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.right-left
  .sqs-slice-custom-form {
  margin-top: 0;
  margin-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-group.flex {
  display: flex;
  align-items: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.restrict-width-1000 {
  width: 100%;
  max-width: 1000px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.restrict-width-750 {
  width: 100%;
  max-width: 750px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.restrict-width-500 {
  width: 100%;
  max-width: 500px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.restrict-width-three-quarters {
  width: 100%;
  max-width: 75%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.restrict-width-two-thirds {
  width: 100%;
  max-width: 66.6666%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.restrict-width-half {
  width: 100%;
  max-width: 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.has-padding {
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-group.has-padding-absolute {
  left: 60px;
  right: 60px;
  width: auto;
  position: absolute;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  div.sqs-slide[data-slide-type="cover-page"]
  .sqs-slide-layer:not(.scroll)
  .align-content-center-vert:not(.scroll) {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  div.sqs-slide[data-slide-type="cover-page"]
  .sqs-slide-layer:not(.scroll)
  .align-content-center-vert:not(.scroll)
  .align-center-vert {
  position: static !important;
  transform: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="logo"]
  h1 {
  margin: 0;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="logo"]
  img {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="heading"]
  h1#sqs-slash-page-header {
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="body"] {
  max-width: 36em;
  margin: 0 auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="body"] p {
  margin: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  p:not(:last-of-type) {
  margin-bottom: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"] {
  z-index: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="gallery"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]:not(.sqs-gallery-grid)
  .sqs-slice-gallery-item,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].sqs-gallery-design-stacked:not(
    .sqs-gallery-grid
  ) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].demo-image
  img,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]:not(.sqs-gallery-grid)
  .sqs-slice-gallery-item.demo-image
  img,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].sqs-gallery-design-stacked:not(
    .sqs-gallery-grid
  ).demo-image
  img {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].sqs-gallery-grid {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].sqs-gallery-grid
  .sqs-slice-gallery-item {
  opacity: 1 !important;
  display: block;
  position: absolute;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].sqs-gallery-grid
  .scrim {
  width: 200%;
  height: 200%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"].sqs-gallery-grid.no-transition
  * {
  transition: none !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container.auto-overlay:not(.sqs-modal-lightbox-open)
  [data-slice-type="gallery"]
  .demo-image
  + .scrim {
  opacity: 0 !important;
  background-color: transparent !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container.auto-overlay.gallery-style-grid
  [data-slice-type="gallery"]
  .scrim {
  background-color: rgba(24, 24, 24, 0.5) !important;
  opacity: 1 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container:not(.auto-image-background-color)
  .sqs-gallery-grid
  .sqs-slice-gallery-item {
  background-color: rgba(153, 153, 153, 0) !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .sqs-slice-gallery-item
  img {
  transition: opacity 1s cubic-bezier(0.33, 0, 0.2, 1);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .sqs-slice-gallery-item.loaded
  img {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .sqs-slice-gallery-item:not(.loaded)
  img {
  opacity: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .grid-gallery-direction-vertical.grid-gallery-ratio-original
  [data-slice-type="gallery"].sqs-gallery-grid {
  width: 100%;
  height: calc(100% + 20px);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .grid-gallery-direction-vertical.grid-gallery-ratio-original
  [data-slice-type="gallery"].sqs-gallery-grid
  .sqs-slice-gallery-item {
  position: absolute;
  visibility: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .gallery-style-grid.gallery-randomize-order
  .sqs-slice-gallery-item:not([data-clone="true"]) {
  width: 0 !important;
  height: 0 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .gallery-style-grid.gallery-loading-animation
  [data-slice-type="gallery"]
  .sqs-slice-gallery-item {
  transform: translate3d(0, 10px, 0);
  transition: opacity 1s cubic-bezier(0.33, 0, 0.2, 1),
    transform 1s cubic-bezier(0.33, 0, 0.2, 1);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .gallery-style-grid.gallery-loading-animation
  [data-slice-type="gallery"]
  .sqs-slice-gallery-item.loaded {
  transform: translate3d(0, 0, 0);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background {
  overflow: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  iframe {
  border-width: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  iframe:not(.background-video) {
  visibility: hidden;
  opacity: 0;
  transform: translatex(-1000%);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.flip-x {
  transform: rotateX(-180deg);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.flip-y {
  transform: rotateY(-180deg);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.flip-x.flip-y {
  transform: rotateX(-180deg) rotateY(-180deg);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.flip-x
  .overlay,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.flip-y
  .overlay {
  transform: translateZ(0);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .background-video,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .default-fallback-image {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  opacity: 0;
  z-index: 0;
  transition: opacity 0.2s ease-in;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .background-video.ready,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .default-fallback-image.ready,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .background-video.loaded,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .default-fallback-image.loaded {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .default-fallback-image {
  top: 50%;
  left: 50%;
  transition: opacity 0.2s 0.2s ease-in;
  transform: translate(-50%, -50%);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .default-fallback-image:not(.buffering) {
  opacity: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .background-video.buffering {
  opacity: 0;
  transition: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .default-fallback-image.buffering {
  opacity: 1;
  z-index: 2;
  transition: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .custom-fallback-image {
  display: none;
  min-width: 100%;
  min-height: 100%;
  opacity: 0;
  z-index: 0;
  transition: opacity 0.2s ease-in;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background
  .custom-fallback-image.loaded {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.filter-blur:not(.mobile) {
  background-color: #7f7f7f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.mobile
  iframe,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.mobile
  .default-fallback-image {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="gallery"]
  .gallery-video-background.mobile
  .custom-fallback-image {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-play-button.icon-wrapper {
  border-radius: 100%;
  width: 64px;
  height: 64px;
  cursor: pointer;
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-play-button.icon-wrapper.hidden {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-outline
  .sqs-slice-play-button {
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .icon-wrapper {
  border-radius: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 170ms ease-in-out, border-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .icon-wrapper
  svg {
  width: 100%;
  height: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .icon-wrapper
  .use-icon {
  transition: fill 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .icon-wrapper
  svg.social {
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper
  > div {
  transform: scale(2);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border.audio-player-icons-size-extra-small
  [data-slice-type="album"]
  .icon-wrapper
  > div {
  transform: scale(1.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border.audio-player-icons-size-small
  [data-slice-type="album"]
  .icon-wrapper
  > div {
  transform: scale(1.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border.audio-player-icons-size-medium
  [data-slice-type="album"]
  .icon-wrapper
  > div {
  transform: scale(1.3);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border.audio-player-icons-size-large
  [data-slice-type="album"]
  .icon-wrapper
  > div {
  transform: scale(1.2);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border.audio-player-icons-size-extra-large
  [data-slice-type="album"]
  .icon-wrapper
  > div {
  transform: scale(1.1);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper {
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-shape-circle
  [data-slice-type="album"]
  .icon-wrapper {
  border-radius: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-shape-rounded
  [data-slice-type="album"]
  .icon-wrapper {
  border-radius: 15%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper {
  width: 24px;
  height: 24px;
  margin: 0 3px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper {
  width: 28px;
  height: 28px;
  margin: 0 4px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper {
  width: 32px;
  height: 32px;
  margin: 0 5px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper {
  width: 36px;
  height: 36px;
  margin: 0 6px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper {
  width: 40px;
  height: 40px;
  margin: 0 7px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper {
  width: 24px;
  height: 24px;
  margin: 0 3px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-small.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper {
  width: 30px;
  height: 30px;
  margin: 0 4px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-small.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper {
  width: 36px;
  height: 36px;
  margin: 0 5px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-medium.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper {
  width: 42px;
  height: 42px;
  margin: 0 6px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-large.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper {
  width: 48px;
  height: 48px;
  margin: 0 7px 0 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper:last-child,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-size-extra-large.audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper:last-child {
  margin-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="album"] {
  position: relative;
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .sqs-slice-album-playlist {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 32px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .controls {
  margin-bottom: 50px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track:not(:last-of-type) {
  margin-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar {
  position: relative;
  cursor: pointer;
  height: 2px;
  padding-bottom: 5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar
  .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 2px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar
  .bar.bg {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar
  .bar.play-bar {
  position: relative;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-title
  a {
  text-decoration: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .button-play-pause
  .pause-icon {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-time {
  float: right;
  text-align: right;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .sqs-slice-album-playlist.playing
  .track {
  opacity: 0.4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .sqs-slice-album-playlist.playing
  .track.selected {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .sqs-slice-album-playlist.playing
  .button-play-pause
  .play-icon {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .sqs-slice-album-playlist.playing
  .button-play-pause
  .pause-icon {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist {
  margin-top: 0;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .controls {
  flex: 0 1 auto;
  margin-bottom: 0;
  margin-right: 0;
  white-space: nowrap;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks {
  margin-left: 15px;
  flex: 1 1 auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.playing
  .tracks
  .track:not(.selected),
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.paused
  .tracks
  .track:not(.selected) {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track:not(.selected):not(:first-child) {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track
  .track-info {
  padding-left: 10px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track
  .track-title {
  padding-right: 10px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track
  .track-title
  a {
  padding-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track
  .track-time {
  float: none;
  padding-left: 10px;
  padding-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist
  .tracks
  .track
  .track-progress-bar {
  flex-grow: 1;
  flex: 1 0 auto;
  padding-bottom: 0;
  margin: auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.demo-album
  .tracks
  .track:first-of-type {
  display: flex;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.demo-album
  .button-previous,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.demo-album
  .button-next {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.stacked {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.stacked
  .tracks {
  margin-left: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.stacked
  .tracks
  .track {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  [data-slice-type="album"]
  .sqs-slice-album-playlist.stacked
  .tracks
  .track
  > * {
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .show-track-title
    .sqs-album-minimal
    [data-slice-type="album"]
    .sqs-slice-album-playlist {
    display: block;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .show-track-title
    .sqs-album-minimal
    [data-slice-type="album"]
    .sqs-slice-album-playlist
    .tracks {
    margin-left: 0;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .show-track-title
    .sqs-album-minimal
    [data-slice-type="album"]
    .sqs-slice-album-playlist
    .tracks
    .track {
    display: block;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .show-track-title
    .sqs-album-minimal
    [data-slice-type="album"]
    .sqs-slice-album-playlist
    .tracks
    .track
    > * {
    margin-top: 10px !important;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .show-track-title
    .sqs-album-minimal
    [data-slice-type="album"]
    .sqs-slice-album-playlist
    .tracks
    .track
    .track-info,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .show-track-title
    .sqs-album-minimal
    [data-slice-type="album"]
    .sqs-slice-album-playlist
    .tracks
    .track
    .track-info
    .track-time {
    padding-left: 0;
    text-align: left;
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper
  > div {
  transform: scale(2);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper {
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-shape-square
  .sqs-slice-play-button.icon-wrapper {
  border-radius: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-shape-circle
  .sqs-slice-play-button.icon-wrapper {
  border-radius: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-shape-rounded
  .sqs-slice-play-button.icon-wrapper {
  border-radius: 15%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-small.video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper {
  width: 48px;
  height: 48px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-small.video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper {
  width: 64px;
  height: 64px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-medium.video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper {
  width: 80px;
  height: 80px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-large.video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper {
  width: 96px;
  height: 96px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-large.video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper {
  width: 112px;
  height: 112px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-small.video-icon-style-border
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-small.video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-small.video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper {
  width: 48px;
  height: 48px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-small.video-icon-style-border
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-small.video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-small.video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper {
  width: 64px;
  height: 64px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-medium.video-icon-style-border
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-medium.video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-medium.video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper {
  width: 80px;
  height: 80px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-large.video-icon-style-border
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-large.video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-large.video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper {
  width: 96px;
  height: 96px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-large.video-icon-style-border
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-large.video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-size-extra-large.video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper {
  width: 112px;
  height: 112px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="map"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .sqs-slice-content-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .gm-style-cc
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .gm-style-cc
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .gmnoprint
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .gmnoprint
  a {
  opacity: 0.5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .gm-style-cc
  *,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"]
  .gmnoprint
  * {
  background-color: transparent !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .map-style-minimal-dark
  .gm-style-cc
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .map-style-minimal-dark
  .gm-style-cc
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .map-style-minimal-dark
  .gmnoprint
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .map-style-minimal-dark
  .gmnoprint
  a {
  opacity: 0.5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul {
  margin: 0;
  padding: 0;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li {
  display: inline-block;
  list-style: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li
  a {
  display: block;
  text-decoration: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul.stacked,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul.stacked,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul.stacked {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul.stacked
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul.stacked
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul.stacked
  li {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li
  + li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li
  + li {
  margin-left: 1em;
}
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li,
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li,
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li
  + li,
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li
  + li {
  margin-left: 0;
}
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li:not(:last-child),
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li:not(:last-child),
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li
  + li:not(:last-child),
.margins-right
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li
  + li:not(:last-child) {
  margin-right: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  a {
  text-align: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul.stacked,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul.stacked {
  max-width: 200px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul.stacked
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul.stacked
  li {
  margin-left: 0;
  margin-bottom: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul.stacked
  li:last-of-type,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul.stacked
  li:last-of-type {
  margin-bottom: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-custom-form a {
  display: inline-block;
  width: auto;
  line-height: 1em;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-custom-form
  span {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  a:empty,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  a:empty,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-custom-form
  a:empty {
  display: none !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-custom-form a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  padding-top: 1em;
  padding-right: 1.44em;
  padding-right: calc(1.44em - 0.05em);
  padding-bottom: 1em;
  padding-left: 1.44em;
}
.button-shape-pill
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  a,
.button-shape-pill
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slice-custom-form
  a,
.button-shape-pill
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  padding-top: 1em;
  padding-right: 1.6em;
  padding-right: calc(1.6em - 0.05em);
  padding-bottom: 1em;
  padding-left: 1.6em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="social-icons"]:not([data-content-empty="true"])
  + [data-compound-type="action"][data-content-empty="true"]
  + [data-slice-type="custom-form"] {
  margin-left: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="social-icons"]:not([data-content-empty="true"])
  + [data-compound-type="action"]:not([data-content-empty="true"]) {
  margin-left: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="social-icons"]:not(:empty)
  + [data-compound-type="action"]:empty
  + [data-slice-type="custom-form"] {
  margin-left: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="social-icons"]:not(:empty)
  + [data-compound-type="action"]:not(:empty) {
  margin-left: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="action"]:not([data-content-empty="true"])
  + [data-slice-type="custom-form"] {
  margin-left: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-rounded-corners
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-rounded-corners
  [data-slice-type="buttons"]
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-rounded-corners
  .sqs-slice-custom-form
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-rounded-corners
  .sqs-slice-custom-form
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-rounded-corners
  [data-compound-type="popup-overlay-action"]
  button[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-rounded-corners
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"] {
  border-radius: 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-pill
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-pill
  [data-slice-type="buttons"]
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-pill
  .sqs-slice-custom-form
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-pill
  .sqs-slice-custom-form
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-pill
  [data-compound-type="popup-overlay-action"]
  button[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-shape-pill
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"] {
  border-radius: 999px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  .sqs-slice-custom-form
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"] {
  border: 2px solid;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-modal-lightbox {
  width: 100%;
  font-size: 14px;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content {
  z-index: 100000000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  background: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper {
  color: #222;
  font-family: inherit;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .form-title {
  font-size: 22px;
  line-height: 1.2em;
  margin-right: 22px;
  color: #333;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .form-inner-wrapper
  form {
  margin-top: 55px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .form-inner-wrapper
  form
  .radio
  .option {
  margin-left: 1px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .lightbox-close {
  position: absolute;
  color: #333;
  font-size: 22px;
  width: 22px;
  line-height: 22px;
  top: 40px;
  right: 40px;
  text-align: center;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-modal-lightbox
    .lightbox-inner {
    background: #fff;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-modal-lightbox
    .lightbox-inner
    .lightbox-content {
    margin-top: 0 !important;
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  .sqs-slide-layer-content.scroll {
  overflow-y: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  .layer-front,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="heading"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="body"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="social-icons"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-compound-type="action"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-compound-type="logo"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="custom-form"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="album"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="video"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="map"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  [data-slice-type="twitter"] {
  display: none !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open.auto-overlay
  .scrim {
  opacity: 0.9 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open.sqs-slide-container:not(.auto-overlay)
  [data-slice-type="gallery"]
  .scrim {
  background: rgba(138, 138, 138, 0.9) !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content {
  top: 0 !important;
  position: fixed;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-background {
  opacity: 0;
}
@media only screen and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-modal-lightbox
    .lightbox-inner {
    background: transparent;
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content {
  background: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 26px;
  line-height: 1.3em;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.02em;
  color: #171717;
  font-size: inherit;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  a {
  color: inherit;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .form-title,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .lightbox-close {
  color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .field-element {
  color: #000;
  color: initial;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul
  li
  a {
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul
  li:first-of-type
  a {
  padding-left: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul
  li:last-of-type
  a {
  padding-right: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="lock"]
  .icon-wrapper {
  margin: 0 auto;
  display: block;
  border-radius: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="lock"]
  .icon-wrapper
  svg {
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .sqs-tweet {
  visibility: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .sqs-tweet:not([data-visible="true"]) {
  position: absolute;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-avatar
  img {
  border-radius: 50%;
  height: 73px;
  width: 73px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-display-name
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-handle
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-timestamp
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-display-name
  a:visited,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-handle
  a:visited,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]
  .tweet-timestamp
  a:visited {
  text-decoration: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  .input-container {
  max-width: 280px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  input[type="password"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  .error-message {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  .arrow-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 44px;
  background-color: transparent;
  transition: opacity 0.2s ease;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  .arrow-icon
  .icon-wrapper {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  .error-message {
  background-color: #f0523d;
  color: #fff;
  text-align: left;
  position: absolute;
  opacity: 0;
  z-index: -1;
  padding: 15px 14px 15px 15px;
  transition: all 0.2s ease;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"].show-error
  .error-message {
  opacity: 1;
  z-index: 0;
  transition: all 0.2s cubic-bezier(0, 0.9, 0.33, 1);
}
@media all and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .touch
    [data-slice-type="password"]
    input[type="password"],
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .touch
    [data-slice-type="password"]
    .error-messge {
    font-size: 16px;
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="social-icons"]
  .icon-wrapper {
  border-color: transparent;
  cursor: pointer;
  display: inline-block;
  width: 32px;
  height: 32px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-regular
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-regular
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-regular
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 5px 10px 5px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-regular
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 20px;
  height: 20px;
  margin: 0 6px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-regular
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 6px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-regular
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 6px 12px 6px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-regular
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 24px;
  height: 24px;
  margin: 0 7px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-regular
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 7px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-regular
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 7px 14px 7px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-regular
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 28px;
  height: 28px;
  margin: 0 8px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-regular
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 8px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-regular
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 8px 16px 8px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-regular
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 32px;
  height: 32px;
  margin: 0 9px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-regular
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 9px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-regular
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 9px 18px 9px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-solid
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 24px;
  height: 24px;
  margin: 0 3px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-border
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-knockout
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-solid
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 3px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-border
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-knockout
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-small.social-icons-style-solid
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 3px 6px 3px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-solid
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 28px;
  height: 28px;
  margin: 0 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-border
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-knockout
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-solid
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-border
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-knockout
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-small.social-icons-style-solid
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 4px 8px 4px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-solid
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 32px;
  height: 32px;
  margin: 0 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-border
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-knockout
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-solid
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-border
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-knockout
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-medium.social-icons-style-solid
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 4px 8px 4px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-solid
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 36px;
  height: 36px;
  margin: 0 5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-border
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-knockout
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-solid
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-border
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-knockout
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-large.social-icons-style-solid
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 5px 10px 5px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-solid
  [data-slice-type="social-icons"]
  .icon-wrapper {
  width: 48px;
  height: 48px;
  margin: 0 6px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-border
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-knockout
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-solid
  .social.stacked
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 6px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-border
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-knockout
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-size-extra-large.social-icons-style-solid
  .social.stacked.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper {
  margin: 6px 12px 6px 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-shape-square
  [data-slice-type="social-icons"]
  .icon-wrapper {
  border-radius: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border.social-icons-shape-circle
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid.social-icons-shape-circle
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout.social-icons-shape-circle
  [data-slice-type="social-icons"]
  .icon-wrapper {
  border-radius: 50%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border.social-icons-shape-rounded
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid.social-icons-shape-rounded
  [data-slice-type="social-icons"]
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout.social-icons-shape-rounded
  [data-slice-type="social-icons"]
  .icon-wrapper {
  border-radius: 15%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper {
  border: 2px solid;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-regular
  [data-slice-type="social-icons"]
  .icon-wrapper
  > div {
  transform: scale(2);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.social:not(.stacked)
  [data-slice-type="social-icons"]
  .icon-wrapper:first-of-type {
  margin-left: 0 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.social:not(.stacked)
  [data-slice-type="social-icons"]
  .icon-wrapper:last-of-type {
  margin-right: 0 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .responsive-wrapper.text-align-left
  [data-slice-type="social-icons"]
  .icon-wrapper:last-of-type {
  margin-right: 0 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .applepodcast
  .use-icon {
  fill: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .applepodcast
  .use-icon {
  fill: rgba(0, 0, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .applepodcast:hover
  .use-icon {
  fill: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .applepodcast {
  border-color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .applepodcast
  .use-icon {
  fill: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .applepodcast:hover {
  background-color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .applepodcast:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .applepodcast
  .use-mask {
  fill: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .applepodcast
  .use-mask {
  fill: rgba(0, 0, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .applepodcast:hover
  .use-mask {
  fill: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .applepodcast
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .applepodcast
  svg.social {
  background-color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .applepodcast
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .applepodcast
  svg.social {
  background-color: rgba(0, 0, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .applepodcast:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .applepodcast:hover
  svg.social {
  background-color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .bandsintown
  .use-icon {
  fill: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .bandsintown
  .use-icon {
  fill: rgba(0, 180, 179, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .bandsintown:hover
  .use-icon {
  fill: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .bandsintown {
  border-color: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .bandsintown
  .use-icon {
  fill: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .bandsintown:hover {
  background-color: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .bandsintown:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .bandsintown
  .use-mask {
  fill: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .bandsintown
  .use-mask {
  fill: rgba(0, 180, 179, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .bandsintown:hover
  .use-mask {
  fill: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .bandsintown
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .bandsintown
  svg.social {
  background-color: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .bandsintown
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .bandsintown
  svg.social {
  background-color: rgba(0, 180, 179, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .bandsintown:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .bandsintown:hover
  svg.social {
  background-color: #00b4b3;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .behance
  .use-icon {
  fill: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .behance
  .use-icon {
  fill: rgba(23, 105, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .behance:hover
  .use-icon {
  fill: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .behance {
  border-color: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .behance
  .use-icon {
  fill: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .behance:hover {
  background-color: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .behance:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .behance
  .use-mask {
  fill: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .behance
  .use-mask {
  fill: rgba(23, 105, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .behance:hover
  .use-mask {
  fill: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .behance
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .behance
  svg.social {
  background-color: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .behance
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .behance
  svg.social {
  background-color: rgba(23, 105, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .behance:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .behance:hover
  svg.social {
  background-color: #1769ff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .codepen
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .codepen
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .codepen:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .codepen {
  border-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .codepen
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .codepen:hover {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .codepen:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .codepen
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .codepen
  .use-mask {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .codepen:hover
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .codepen
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .codepen
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .codepen
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .codepen
  svg.social {
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .codepen:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .codepen:hover
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .dribbble
  .use-icon {
  fill: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .dribbble
  .use-icon {
  fill: rgba(234, 76, 137, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .dribbble:hover
  .use-icon {
  fill: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dribbble {
  border-color: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dribbble
  .use-icon {
  fill: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dribbble:hover {
  background-color: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dribbble:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .dribbble
  .use-mask {
  fill: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .dribbble
  .use-mask {
  fill: rgba(234, 76, 137, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .dribbble:hover
  .use-mask {
  fill: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .dribbble
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .dribbble
  svg.social {
  background-color: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dribbble
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dribbble
  svg.social {
  background-color: rgba(234, 76, 137, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dribbble:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dribbble:hover
  svg.social {
  background-color: #ea4c89;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .dropbox
  .use-icon {
  fill: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .dropbox
  .use-icon {
  fill: rgba(0, 126, 229, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .dropbox:hover
  .use-icon {
  fill: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dropbox {
  border-color: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dropbox
  .use-icon {
  fill: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dropbox:hover {
  background-color: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .dropbox:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .dropbox
  .use-mask {
  fill: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .dropbox
  .use-mask {
  fill: rgba(0, 126, 229, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .dropbox:hover
  .use-mask {
  fill: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .dropbox
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .dropbox
  svg.social {
  background-color: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dropbox
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dropbox
  svg.social {
  background-color: rgba(0, 126, 229, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dropbox:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .dropbox:hover
  svg.social {
  background-color: #007ee5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .email
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .email
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .email:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .email {
  border-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .email
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .email:hover {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .email:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .email
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .email
  .use-mask {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .email:hover
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .email
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .email
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .email
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .email
  svg.social {
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .email:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .email:hover
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .facebook
  .use-icon {
  fill: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .facebook
  .use-icon {
  fill: rgba(59, 89, 152, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .facebook:hover
  .use-icon {
  fill: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .facebook {
  border-color: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .facebook
  .use-icon {
  fill: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .facebook:hover {
  background-color: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .facebook:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .facebook
  .use-mask {
  fill: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .facebook
  .use-mask {
  fill: rgba(59, 89, 152, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .facebook:hover
  .use-mask {
  fill: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .facebook
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .facebook
  svg.social {
  background-color: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .facebook
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .facebook
  svg.social {
  background-color: rgba(59, 89, 152, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .facebook:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .facebook:hover
  svg.social {
  background-color: #3b5998;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .fivehundredpix
  .use-icon {
  fill: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .fivehundredpix
  .use-icon {
  fill: rgba(0, 153, 229, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .fivehundredpix:hover
  .use-icon {
  fill: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .fivehundredpix {
  border-color: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .fivehundredpix
  .use-icon {
  fill: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .fivehundredpix:hover {
  background-color: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .fivehundredpix:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .fivehundredpix
  .use-mask {
  fill: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .fivehundredpix
  .use-mask {
  fill: rgba(0, 153, 229, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .fivehundredpix:hover
  .use-mask {
  fill: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .fivehundredpix
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .fivehundredpix
  svg.social {
  background-color: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .fivehundredpix
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .fivehundredpix
  svg.social {
  background-color: rgba(0, 153, 229, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .fivehundredpix:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .fivehundredpix:hover
  svg.social {
  background-color: #0099e5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .flickr
  .use-icon {
  fill: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .flickr
  .use-icon {
  fill: rgba(0, 99, 220, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .flickr:hover
  .use-icon {
  fill: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .flickr {
  border-color: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .flickr
  .use-icon {
  fill: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .flickr:hover {
  background-color: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .flickr:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .flickr
  .use-mask {
  fill: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .flickr
  .use-mask {
  fill: rgba(0, 99, 220, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .flickr:hover
  .use-mask {
  fill: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .flickr
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .flickr
  svg.social {
  background-color: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .flickr
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .flickr
  svg.social {
  background-color: rgba(0, 99, 220, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .flickr:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .flickr:hover
  svg.social {
  background-color: #0063dc;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .foursquare
  .use-icon {
  fill: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .foursquare
  .use-icon {
  fill: rgba(249, 72, 119, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .foursquare:hover
  .use-icon {
  fill: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .foursquare {
  border-color: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .foursquare
  .use-icon {
  fill: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .foursquare:hover {
  background-color: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .foursquare:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .foursquare
  .use-mask {
  fill: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .foursquare
  .use-mask {
  fill: rgba(249, 72, 119, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .foursquare:hover
  .use-mask {
  fill: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .foursquare
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .foursquare
  svg.social {
  background-color: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .foursquare
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .foursquare
  svg.social {
  background-color: rgba(249, 72, 119, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .foursquare:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .foursquare:hover
  svg.social {
  background-color: #f94877;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .github
  .use-icon {
  fill: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .github
  .use-icon {
  fill: rgba(65, 131, 196, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .github:hover
  .use-icon {
  fill: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .github {
  border-color: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .github
  .use-icon {
  fill: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .github:hover {
  background-color: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .github:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .github
  .use-mask {
  fill: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .github
  .use-mask {
  fill: rgba(65, 131, 196, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .github:hover
  .use-mask {
  fill: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .github
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .github
  svg.social {
  background-color: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .github
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .github
  svg.social {
  background-color: rgba(65, 131, 196, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .github:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .github:hover
  svg.social {
  background-color: #4183c4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .goodreads
  .use-icon {
  fill: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .goodreads
  .use-icon {
  fill: rgba(56, 33, 16, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .goodreads:hover
  .use-icon {
  fill: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .goodreads {
  border-color: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .goodreads
  .use-icon {
  fill: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .goodreads:hover {
  background-color: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .goodreads:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .goodreads
  .use-mask {
  fill: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .goodreads
  .use-mask {
  fill: rgba(56, 33, 16, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .goodreads:hover
  .use-mask {
  fill: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .goodreads
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .goodreads
  svg.social {
  background-color: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .goodreads
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .goodreads
  svg.social {
  background-color: rgba(56, 33, 16, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .goodreads:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .goodreads:hover
  svg.social {
  background-color: #382110;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .googleplay
  .use-icon {
  fill: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .googleplay
  .use-icon {
  fill: rgba(90, 223, 203, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .googleplay:hover
  .use-icon {
  fill: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .googleplay {
  border-color: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .googleplay
  .use-icon {
  fill: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .googleplay:hover {
  background-color: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .googleplay:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .googleplay
  .use-mask {
  fill: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .googleplay
  .use-mask {
  fill: rgba(90, 223, 203, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .googleplay:hover
  .use-mask {
  fill: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .googleplay
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .googleplay
  svg.social {
  background-color: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .googleplay
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .googleplay
  svg.social {
  background-color: rgba(90, 223, 203, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .googleplay:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .googleplay:hover
  svg.social {
  background-color: #5adfcb;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .google
  .use-icon {
  fill: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .google
  .use-icon {
  fill: rgba(220, 78, 65, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .google:hover
  .use-icon {
  fill: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .google {
  border-color: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .google
  .use-icon {
  fill: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .google:hover {
  background-color: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .google:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .google
  .use-mask {
  fill: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .google
  .use-mask {
  fill: rgba(220, 78, 65, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .google:hover
  .use-mask {
  fill: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .google
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .google
  svg.social {
  background-color: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .google
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .google
  svg.social {
  background-color: rgba(220, 78, 65, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .google:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .google:hover
  svg.social {
  background-color: #dc4e41;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .houzz
  .use-icon {
  fill: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .houzz
  .use-icon {
  fill: rgba(122, 193, 67, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .houzz:hover
  .use-icon {
  fill: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .houzz {
  border-color: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .houzz
  .use-icon {
  fill: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .houzz:hover {
  background-color: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .houzz:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .houzz
  .use-mask {
  fill: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .houzz
  .use-mask {
  fill: rgba(122, 193, 67, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .houzz:hover
  .use-mask {
  fill: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .houzz
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .houzz
  svg.social {
  background-color: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .houzz
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .houzz
  svg.social {
  background-color: rgba(122, 193, 67, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .houzz:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .houzz:hover
  svg.social {
  background-color: #7ac143;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .imdb
  .use-icon {
  fill: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .imdb
  .use-icon {
  fill: rgba(230, 185, 30, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .imdb:hover
  .use-icon {
  fill: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .imdb {
  border-color: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .imdb
  .use-icon {
  fill: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .imdb:hover {
  background-color: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .imdb:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .imdb
  .use-mask {
  fill: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .imdb
  .use-mask {
  fill: rgba(230, 185, 30, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .imdb:hover
  .use-mask {
  fill: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .imdb
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .imdb
  svg.social {
  background-color: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .imdb
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .imdb
  svg.social {
  background-color: rgba(230, 185, 30, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .imdb:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .imdb:hover
  svg.social {
  background-color: #e6b91e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .instagram
  .use-icon {
  fill: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .instagram
  .use-icon {
  fill: rgba(228, 64, 95, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .instagram:hover
  .use-icon {
  fill: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .instagram {
  border-color: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .instagram
  .use-icon {
  fill: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .instagram:hover {
  background-color: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .instagram:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .instagram
  .use-mask {
  fill: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .instagram
  .use-mask {
  fill: rgba(228, 64, 95, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .instagram:hover
  .use-mask {
  fill: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .instagram
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .instagram
  svg.social {
  background-color: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .instagram
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .instagram
  svg.social {
  background-color: rgba(228, 64, 95, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .instagram:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .instagram:hover
  svg.social {
  background-color: #e4405f;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .itunes
  .use-icon {
  fill: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .itunes
  .use-icon {
  fill: rgba(236, 70, 82, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .itunes:hover
  .use-icon {
  fill: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .itunes {
  border-color: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .itunes
  .use-icon {
  fill: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .itunes:hover {
  background-color: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .itunes:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .itunes
  .use-mask {
  fill: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .itunes
  .use-mask {
  fill: rgba(236, 70, 82, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .itunes:hover
  .use-mask {
  fill: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .itunes
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .itunes
  svg.social {
  background-color: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .itunes
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .itunes
  svg.social {
  background-color: rgba(236, 70, 82, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .itunes:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .itunes:hover
  svg.social {
  background-color: #ec4652;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .linkedin
  .use-icon {
  fill: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .linkedin
  .use-icon {
  fill: rgba(9, 118, 180, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .linkedin:hover
  .use-icon {
  fill: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .linkedin {
  border-color: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .linkedin
  .use-icon {
  fill: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .linkedin:hover {
  background-color: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .linkedin:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .linkedin
  .use-mask {
  fill: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .linkedin
  .use-mask {
  fill: rgba(9, 118, 180, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .linkedin:hover
  .use-mask {
  fill: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .linkedin
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .linkedin
  svg.social {
  background-color: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .linkedin
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .linkedin
  svg.social {
  background-color: rgba(9, 118, 180, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .linkedin:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .linkedin:hover
  svg.social {
  background-color: #0976b4;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .medium
  .use-icon {
  fill: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .medium
  .use-icon {
  fill: rgba(0, 171, 108, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .medium:hover
  .use-icon {
  fill: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .medium {
  border-color: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .medium
  .use-icon {
  fill: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .medium:hover {
  background-color: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .medium:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .medium
  .use-mask {
  fill: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .medium
  .use-mask {
  fill: rgba(0, 171, 108, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .medium:hover
  .use-mask {
  fill: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .medium
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .medium
  svg.social {
  background-color: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .medium
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .medium
  svg.social {
  background-color: rgba(0, 171, 108, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .medium:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .medium:hover
  svg.social {
  background-color: #00ab6c;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .meetup
  .use-icon {
  fill: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .meetup
  .use-icon {
  fill: rgba(224, 57, 62, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .meetup:hover
  .use-icon {
  fill: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .meetup {
  border-color: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .meetup
  .use-icon {
  fill: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .meetup:hover {
  background-color: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .meetup:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .meetup
  .use-mask {
  fill: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .meetup
  .use-mask {
  fill: rgba(224, 57, 62, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .meetup:hover
  .use-mask {
  fill: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .meetup
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .meetup
  svg.social {
  background-color: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .meetup
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .meetup
  svg.social {
  background-color: rgba(224, 57, 62, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .meetup:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .meetup:hover
  svg.social {
  background-color: #e0393e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .pinterest
  .use-icon {
  fill: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .pinterest
  .use-icon {
  fill: rgba(204, 33, 39, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .pinterest:hover
  .use-icon {
  fill: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .pinterest {
  border-color: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .pinterest
  .use-icon {
  fill: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .pinterest:hover {
  background-color: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .pinterest:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .pinterest
  .use-mask {
  fill: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .pinterest
  .use-mask {
  fill: rgba(204, 33, 39, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .pinterest:hover
  .use-mask {
  fill: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .pinterest
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .pinterest
  svg.social {
  background-color: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .pinterest
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .pinterest
  svg.social {
  background-color: rgba(204, 33, 39, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .pinterest:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .pinterest:hover
  svg.social {
  background-color: #cc2127;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .rdio
  .use-icon {
  fill: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .rdio
  .use-icon {
  fill: rgba(0, 110, 210, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .rdio:hover
  .use-icon {
  fill: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rdio {
  border-color: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rdio
  .use-icon {
  fill: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rdio:hover {
  background-color: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rdio:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .rdio
  .use-mask {
  fill: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .rdio
  .use-mask {
  fill: rgba(0, 110, 210, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .rdio:hover
  .use-mask {
  fill: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .rdio
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .rdio
  svg.social {
  background-color: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rdio
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rdio
  svg.social {
  background-color: rgba(0, 110, 210, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rdio:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rdio:hover
  svg.social {
  background-color: #006ed2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .reddit
  .use-icon {
  fill: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .reddit
  .use-icon {
  fill: rgba(255, 69, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .reddit:hover
  .use-icon {
  fill: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .reddit {
  border-color: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .reddit
  .use-icon {
  fill: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .reddit:hover {
  background-color: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .reddit:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .reddit
  .use-mask {
  fill: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .reddit
  .use-mask {
  fill: rgba(255, 69, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .reddit:hover
  .use-mask {
  fill: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .reddit
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .reddit
  svg.social {
  background-color: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .reddit
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .reddit
  svg.social {
  background-color: rgba(255, 69, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .reddit:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .reddit:hover
  svg.social {
  background-color: #ff4500;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .rss
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .rss
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .rss:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rss {
  border-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rss
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rss:hover {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .rss:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .rss
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .rss
  .use-mask {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .rss:hover
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .rss
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .rss
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rss
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rss
  svg.social {
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rss:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .rss:hover
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .smugmug
  .use-icon {
  fill: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .smugmug
  .use-icon {
  fill: rgba(125, 187, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .smugmug:hover
  .use-icon {
  fill: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .smugmug {
  border-color: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .smugmug
  .use-icon {
  fill: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .smugmug:hover {
  background-color: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .smugmug:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .smugmug
  .use-mask {
  fill: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .smugmug
  .use-mask {
  fill: rgba(125, 187, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .smugmug:hover
  .use-mask {
  fill: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .smugmug
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .smugmug
  svg.social {
  background-color: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .smugmug
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .smugmug
  svg.social {
  background-color: rgba(125, 187, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .smugmug:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .smugmug:hover
  svg.social {
  background-color: #7dbb00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .snapchat
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .snapchat
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .snapchat:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .snapchat {
  border-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .snapchat
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .snapchat:hover {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .snapchat:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .snapchat
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .snapchat
  .use-mask {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .snapchat:hover
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .snapchat
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .snapchat
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .snapchat
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .snapchat
  svg.social {
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .snapchat:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .snapchat:hover
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .soundcloud
  .use-icon {
  fill: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .soundcloud
  .use-icon {
  fill: rgba(255, 102, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .soundcloud:hover
  .use-icon {
  fill: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .soundcloud {
  border-color: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .soundcloud
  .use-icon {
  fill: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .soundcloud:hover {
  background-color: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .soundcloud:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .soundcloud
  .use-mask {
  fill: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .soundcloud
  .use-mask {
  fill: rgba(255, 102, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .soundcloud:hover
  .use-mask {
  fill: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .soundcloud
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .soundcloud
  svg.social {
  background-color: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .soundcloud
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .soundcloud
  svg.social {
  background-color: rgba(255, 102, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .soundcloud:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .soundcloud:hover
  svg.social {
  background-color: #f60;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .spotify
  .use-icon {
  fill: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .spotify
  .use-icon {
  fill: rgba(132, 189, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .spotify:hover
  .use-icon {
  fill: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .spotify {
  border-color: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .spotify
  .use-icon {
  fill: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .spotify:hover {
  background-color: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .spotify:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .spotify
  .use-mask {
  fill: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .spotify
  .use-mask {
  fill: rgba(132, 189, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .spotify:hover
  .use-mask {
  fill: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .spotify
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .spotify
  svg.social {
  background-color: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .spotify
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .spotify
  svg.social {
  background-color: rgba(132, 189, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .spotify:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .spotify:hover
  svg.social {
  background-color: #84bd00;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .squarespace
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .squarespace
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .squarespace:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .squarespace {
  border-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .squarespace
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .squarespace:hover {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .squarespace:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .squarespace
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .squarespace
  .use-mask {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .squarespace:hover
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .squarespace
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .squarespace
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .squarespace
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .squarespace
  svg.social {
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .squarespace:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .squarespace:hover
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .stitcher
  .use-icon {
  fill: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .stitcher
  .use-icon {
  fill: rgba(30, 169, 225, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .stitcher:hover
  .use-icon {
  fill: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stitcher {
  border-color: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stitcher
  .use-icon {
  fill: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stitcher:hover {
  background-color: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stitcher:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .stitcher
  .use-mask {
  fill: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .stitcher
  .use-mask {
  fill: rgba(30, 169, 225, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .stitcher:hover
  .use-mask {
  fill: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .stitcher
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .stitcher
  svg.social {
  background-color: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stitcher
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stitcher
  svg.social {
  background-color: rgba(30, 169, 225, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stitcher:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stitcher:hover
  svg.social {
  background-color: #1ea9e1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .stumbleupon
  .use-icon {
  fill: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .stumbleupon
  .use-icon {
  fill: rgba(235, 73, 36, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .stumbleupon:hover
  .use-icon {
  fill: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stumbleupon {
  border-color: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stumbleupon
  .use-icon {
  fill: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stumbleupon:hover {
  background-color: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .stumbleupon:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .stumbleupon
  .use-mask {
  fill: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .stumbleupon
  .use-mask {
  fill: rgba(235, 73, 36, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .stumbleupon:hover
  .use-mask {
  fill: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .stumbleupon
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .stumbleupon
  svg.social {
  background-color: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stumbleupon
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stumbleupon
  svg.social {
  background-color: rgba(235, 73, 36, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stumbleupon:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .stumbleupon:hover
  svg.social {
  background-color: #eb4924;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .thedots
  .use-icon {
  fill: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .thedots
  .use-icon {
  fill: rgba(140, 128, 112, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .thedots:hover
  .use-icon {
  fill: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .thedots {
  border-color: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .thedots
  .use-icon {
  fill: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .thedots:hover {
  background-color: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .thedots:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .thedots
  .use-mask {
  fill: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .thedots
  .use-mask {
  fill: rgba(140, 128, 112, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .thedots:hover
  .use-mask {
  fill: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .thedots
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .thedots
  svg.social {
  background-color: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .thedots
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .thedots
  svg.social {
  background-color: rgba(140, 128, 112, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .thedots:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .thedots:hover
  svg.social {
  background-color: #8c8070;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .tidal
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .tidal
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .tidal:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tidal {
  border-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tidal
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tidal:hover {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tidal:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .tidal
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .tidal
  .use-mask {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .tidal:hover
  .use-mask {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .tidal
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .tidal
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tidal
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tidal
  svg.social {
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tidal:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tidal:hover
  svg.social {
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .tumblr
  .use-icon {
  fill: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .tumblr
  .use-icon {
  fill: rgba(53, 70, 93, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .tumblr:hover
  .use-icon {
  fill: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tumblr {
  border-color: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tumblr
  .use-icon {
  fill: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tumblr:hover {
  background-color: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .tumblr:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .tumblr
  .use-mask {
  fill: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .tumblr
  .use-mask {
  fill: rgba(53, 70, 93, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .tumblr:hover
  .use-mask {
  fill: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .tumblr
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .tumblr
  svg.social {
  background-color: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tumblr
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tumblr
  svg.social {
  background-color: rgba(53, 70, 93, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tumblr:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .tumblr:hover
  svg.social {
  background-color: #35465d;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .twitch
  .use-icon {
  fill: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .twitch
  .use-icon {
  fill: rgba(100, 65, 165, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .twitch:hover
  .use-icon {
  fill: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitch {
  border-color: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitch
  .use-icon {
  fill: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitch:hover {
  background-color: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitch:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .twitch
  .use-mask {
  fill: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .twitch
  .use-mask {
  fill: rgba(100, 65, 165, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .twitch:hover
  .use-mask {
  fill: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .twitch
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .twitch
  svg.social {
  background-color: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitch
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitch
  svg.social {
  background-color: rgba(100, 65, 165, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitch:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitch:hover
  svg.social {
  background-color: #6441a5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .twitter
  .use-icon {
  fill: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .twitter
  .use-icon {
  fill: rgba(85, 172, 238, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .twitter:hover
  .use-icon {
  fill: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitter {
  border-color: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitter
  .use-icon {
  fill: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitter:hover {
  background-color: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .twitter:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .twitter
  .use-mask {
  fill: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .twitter
  .use-mask {
  fill: rgba(85, 172, 238, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .twitter:hover
  .use-mask {
  fill: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .twitter
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .twitter
  svg.social {
  background-color: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitter
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitter
  svg.social {
  background-color: rgba(85, 172, 238, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitter:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .twitter:hover
  svg.social {
  background-color: #55acee;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .vevo
  .use-icon {
  fill: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vevo
  .use-icon {
  fill: rgba(255, 0, 49, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vevo:hover
  .use-icon {
  fill: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vevo {
  border-color: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vevo
  .use-icon {
  fill: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vevo:hover {
  background-color: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vevo:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .vevo
  .use-mask {
  fill: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vevo
  .use-mask {
  fill: rgba(255, 0, 49, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vevo:hover
  .use-mask {
  fill: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vevo
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vevo
  svg.social {
  background-color: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vevo
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vevo
  svg.social {
  background-color: rgba(255, 0, 49, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vevo:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vevo:hover
  svg.social {
  background-color: #ff0031;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .vimeo
  .use-icon {
  fill: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vimeo
  .use-icon {
  fill: rgba(26, 183, 234, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vimeo:hover
  .use-icon {
  fill: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vimeo {
  border-color: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vimeo
  .use-icon {
  fill: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vimeo:hover {
  background-color: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vimeo:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .vimeo
  .use-mask {
  fill: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vimeo
  .use-mask {
  fill: rgba(26, 183, 234, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vimeo:hover
  .use-mask {
  fill: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vimeo
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vimeo
  svg.social {
  background-color: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vimeo
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vimeo
  svg.social {
  background-color: rgba(26, 183, 234, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vimeo:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vimeo:hover
  svg.social {
  background-color: #1ab7ea;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .vine
  .use-icon {
  fill: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vine
  .use-icon {
  fill: rgba(0, 180, 136, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vine:hover
  .use-icon {
  fill: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vine {
  border-color: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vine
  .use-icon {
  fill: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vine:hover {
  background-color: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vine:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .vine
  .use-mask {
  fill: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vine
  .use-mask {
  fill: rgba(0, 180, 136, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vine:hover
  .use-mask {
  fill: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vine
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vine
  svg.social {
  background-color: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vine
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vine
  svg.social {
  background-color: rgba(0, 180, 136, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vine:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vine:hover
  svg.social {
  background-color: #00b488;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .vsco
  .use-icon {
  fill: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vsco
  .use-icon {
  fill: rgba(174, 153, 90, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .vsco:hover
  .use-icon {
  fill: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vsco {
  border-color: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vsco
  .use-icon {
  fill: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vsco:hover {
  background-color: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .vsco:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .vsco
  .use-mask {
  fill: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vsco
  .use-mask {
  fill: rgba(174, 153, 90, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .vsco:hover
  .use-mask {
  fill: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vsco
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .vsco
  svg.social {
  background-color: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vsco
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vsco
  svg.social {
  background-color: rgba(174, 153, 90, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vsco:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .vsco:hover
  svg.social {
  background-color: #ae995a;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .yelp
  .use-icon {
  fill: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .yelp
  .use-icon {
  fill: rgba(196, 18, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .yelp:hover
  .use-icon {
  fill: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .yelp {
  border-color: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .yelp
  .use-icon {
  fill: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .yelp:hover {
  background-color: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .yelp:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .yelp
  .use-mask {
  fill: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .yelp
  .use-mask {
  fill: rgba(196, 18, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .yelp:hover
  .use-mask {
  fill: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .yelp
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .yelp
  svg.social {
  background-color: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .yelp
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .yelp
  svg.social {
  background-color: rgba(196, 18, 0, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .yelp:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .yelp:hover
  svg.social {
  background-color: #c41200;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]
  .youtube
  .use-icon {
  fill: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .youtube
  .use-icon {
  fill: rgba(229, 45, 39, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .youtube:hover
  .use-icon {
  fill: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .youtube {
  border-color: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .youtube
  .use-icon {
  fill: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .youtube:hover {
  background-color: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-border
  [data-slice-type="social-icons"]
  .youtube:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]
  .youtube
  .use-mask {
  fill: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .youtube
  .use-mask {
  fill: rgba(229, 45, 39, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .youtube:hover
  .use-mask {
  fill: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .youtube
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]
  .youtube
  svg.social {
  background-color: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .youtube
  .use-icon {
  fill: rgba(255, 255, 255, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .youtube
  svg.social {
  background-color: rgba(229, 45, 39, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .youtube:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-color-standard.social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .youtube:hover
  svg.social {
  background-color: #e52d27;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .lock-screen :focus {
  outline-offset: 4px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-screen
  :focus:not(:focus-visible) {
  outline: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .icon-wrapper {
  transition: background-color 170ms ease-in-out, border-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .use-icon,
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .use-mask {
  transition: fill 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] svg.social {
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  svg.social {
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  svg.social {
  transition: background-color 0ms 0ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper:hover
  svg.social {
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="action"]:not([data-content-empty="true"])
  + [data-slice-type="custom-form"][data-content-empty="true"]
  + [data-slice-type="social-icons"]:not([data-content-empty="true"]) {
  margin-left: 1em;
}
@media all and (max-width: 1020px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-layer.split-fifty {
    position: relative;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-layer.split-fifty
    > .sqs-slide-layer-content {
    width: 100%;
    height: auto;
    min-height: 50%;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-layer.split-fifty
    .sqs-slice-group.bottom-full-width {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-layer.split-fifty
    [data-slice-type="body"] {
    margin-left: auto;
    margin-right: auto;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    [data-slice-type="twitter"]
    .tweet-avatar
    img {
    height: 64px;
    width: 64px;
  }
}
@media all and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slide-layer-content {
    padding: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.align-center {
    padding: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-group.bottom {
    bottom: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.full-width {
    left: 20px;
    right: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.bottom-full-width {
    bottom: calc(60px - 20px);
    left: 20px;
    right: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.top-full-width {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.has-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.has-padding-absolute {
    left: 20px;
    right: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-layer.split-fifty
    > .sqs-slide-layer-content {
    padding: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-play-button.icon-wrapper {
    width: 48px !important;
    height: 48px !important;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    [data-slice-type="twitter"]
    .tweet-avatar
    img {
    height: 56px;
    width: 56px;
  }
}
@media all and (max-height: 900px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slice-group.bottom-full-width {
    bottom: calc(60px - 20px);
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .branding-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="logo"][data-slice-type="heading"]
  h1 {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "'Helvetica Neue', Helvetica, Arial, sans-serif";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0.02em;
  line-height: 1em;
  color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="logo"][data-slice-type="image"] {
  max-height: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="logo"][data-slice-type="image"]
  img {
  max-height: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .heading-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-size: 130px;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: -2px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="heading"]:not([data-compound-type])
  h1#sqs-slash-page-header {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-size: 130px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 1em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0px;
  line-height: 1.1em;
  color: #111;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .body-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0.5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="body"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 26px;
  line-height: 1.3em;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.02em;
  color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] [data-slice-type="body"] p,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  p {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 26px;
  line-height: 1.3em;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.02em;
  color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  p
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  p
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  p
  a:visited,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  p
  a:visited {
  color: #171717;
  text-decoration: none;
  border-bottom: solid 1px rgba(23, 23, 23, 0.2);
  transition: border-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  p
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  p
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  p
  a:focus,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  p
  a:focus {
  color: #171717;
  border-bottom-color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  .disclaimer-container,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .disclaimer-container {
  margin-top: 14px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  .disclaimer-container,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .disclaimer-container,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="body"]
  .disclaimer-container
  p,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-content
  .lightbox-inner
  .lightbox-content
  .form-wrapper
  .disclaimer-container
  p {
  font-size: 14px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .countdown-value-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-size: 150px;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .countdown-numeric-label-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 300;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"] {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]::after,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]::before {
  content: " ";
  display: table;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]::after {
  clear: both;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-message-pre,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-message-post {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit {
  margin: 0 0 25px;
  float: left;
  box-sizing: border-box;
  text-align: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit
  > * {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit-value,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-event-message {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 150px;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
  color: #fff;
  margin: 0 0.1em;
  min-width: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit-label {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-weight: 300;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
  color: #fff;
  margin-top: 0.5em;
}
.countdown-numeric-label-font-size-small
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit-label {
  font-size: 15px;
}
.countdown-numeric-label-font-size-medium
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit-label {
  font-size: 20px;
}
.countdown-numeric-label-font-size-large
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="numeric"]
  .countdown-unit-label {
  font-size: 30px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"] {
  font-size: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit-value,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit-label,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-message-pre,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-message-post,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-event-message {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 150px;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit {
  display: inline;
  white-space: nowrap;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit[data-unit-type="seconds"] {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit
  br {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit
  * {
  display: inline-block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit
  .countdown-unit-value:after,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  .countdown-unit:last-of-type:not(.leadingPunctuation)
  .countdown-unit-label:after {
  content: "\00a0";
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="countdown"]
  .countdown-content[data-format="textual"]
  > div:not(:last-of-type)
  .countdown-unit-label:after {
  content: ",\00a0";
}
@media all and (max-width: 1020px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .countdown-numeric-label-font-size-small
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit-label {
    font-size: 13px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .countdown-numeric-label-font-size-medium
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit-label {
    font-size: 17px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .countdown-numeric-label-font-size-large
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit-label {
    font-size: 25px;
  }
}
@media all and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .countdown-numeric-label-font-size-small
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit-label {
    font-size: 11px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .countdown-numeric-label-font-size-medium
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit-label {
    font-size: 13px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .countdown-numeric-label-font-size-large
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit-label {
    font-size: 15px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit:first-of-type:nth-last-of-type(5)
    ~ .countdown-unit:nth-of-type(4),
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    [data-slice-type="countdown"]
    .countdown-content[data-format="numeric"]
    .countdown-unit:first-of-type:nth-last-of-type(6)
    ~ .countdown-unit:nth-of-type(4) {
    clear: both;
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slide {
  background: rgba(0, 0, 0, 0.3);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .enable-page-border.sqs-slide-container
  > .sqs-slide {
  border-style: solid;
  border-width: 16px;
  border-color: #fff;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .enable-page-border.page-border-size-extra-small.sqs-slide-container
  > .sqs-slide {
  border-width: 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .enable-page-border.page-border-size-small.sqs-slide-container
  > .sqs-slide {
  border-width: 8px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .enable-page-border.page-border-size-medium.sqs-slide-container
  > .sqs-slide {
  border-width: 16px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .enable-page-border.page-border-size-large.sqs-slide-container
  > .sqs-slide {
  border-width: 24px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .enable-page-border.page-border-size-extra-large.sqs-slide-container
  > .sqs-slide {
  border-width: 32px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .scrim {
  transition: background 170ms ease-out, opacity 170ms ease-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container:not(.auto-overlay)
  .scrim {
  background-color: rgba(138, 138, 138, 0) !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container:not(.auto-image-background-color)
  [data-slice-type="gallery"]
  .demo-image {
  background-color: transparent !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container:not(.auto-image-background-color)
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]:not([data-content-empty="true"])
  .sqs-slice-gallery-item {
  background-color: rgba(153, 153, 153, 0) !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container
  .gallery-video-background
  + .scrim {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-modal-lightbox-open
  .sqs-slide-container
  .gallery-video-background
  + .scrim {
  mix-blend-mode: normal !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-multiply
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: multiply;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-screen
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: screen;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-overlay
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: overlay;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-darken
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: darken;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-lighten
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: lighten;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-color-dodge
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: color-dodge;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-color-burn
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: color-burn;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-hard-light
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: hard-light;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-soft-light
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: soft-light;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-difference
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: difference;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-overlay-color-blend-mode-exclusion
  div:not(.sqs-gallery-circle)
  > [data-slice-type="gallery"]
  > .gallery-video-background
  + .scrim {
  mix-blend-mode: exclusion;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .navigation-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: "'Helvetica Neue', Helvetica, Arial, sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul
  li
  a {
  transition: color 100ms ease-in-out;
  color: #181818;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="navigation"]
  ul
  li
  a:hover {
  color: rgba(24, 24, 24, 0.6);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"] {
  display: block !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-inline
  [data-slice-type="buttons"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-auto
  [data-slice-type="buttons"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-inline-auto
  [data-slice-type="buttons"]
  ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-inline
  [data-slice-type="buttons"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-auto
  [data-slice-type="buttons"]
  ul
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-inline-auto
  [data-slice-type="buttons"]
  ul
  li {
  flex: 0 1 44%;
  margin: 3%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-inline
  [data-slice-type="buttons"]
  ul
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-auto
  [data-slice-type="buttons"]
  ul
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-inline-auto
  [data-slice-type="buttons"]
  ul
  li
  a {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-stacked
  [data-slice-type="buttons"]
  ul
  li {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-layout-style-stacked
  [data-slice-type="buttons"]
  ul
  li
  + li {
  margin: 0;
  margin-top: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .button-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  ul,
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sqs-slice-custom-form,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .form-wrapper
  input[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-size: 16px;
  letter-spacing: 2px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-solid
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-solid
  .sqs-slice-custom-form
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-solid
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-solid
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  background: #000;
  color: #fff;
  border-style: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  .sqs-slice-custom-form
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  transition: color 170ms ease-in-out, border-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-slice-type="buttons"]
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  .sqs-slice-custom-form
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"]:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"]:hover {
  color: #efefef;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  background-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-slice-type="buttons"]
  li,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  .sqs-slice-custom-form
  span,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-slice-type="buttons"]
  li:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  .sqs-slice-custom-form
  span:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"]:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"]:hover {
  background-color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-slice-type="buttons"]
  li:hover
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  .sqs-slice-custom-form
  span:hover
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"]:hover
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-outline
  [data-compound-type="popup-overlay-action"]
  button[type="submit"]:hover
  a {
  border-color: transparent;
  color: #efefef;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  [data-slice-type="buttons"]
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  .sqs-slice-custom-form
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  [data-compound-type="popup-overlay-action"]
  button[type="submit"] {
  background-color: #000;
  color: #fff;
  border-style: none;
  box-shadow: 0px 0.2em 0px 0px #262626;
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  [data-slice-type="buttons"]
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  .sqs-slice-custom-form
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"]:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .button-style-raised
  [data-compound-type="popup-overlay-action"]
  button[type="submit"]:hover {
  background-color: #080808;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-regular
  [data-slice-type="social-icons"]
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-regular
  [data-slice-type="social-icons"]
  svg.social {
  background-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-regular
  [data-slice-type="social-icons"]
  .use-mask {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper {
  border-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  svg.social {
  background-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  .use-mask {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout
  [data-slice-type="social-icons"]
  .use-mask {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout
  [data-slice-type="social-icons"]
  svg.social {
  background-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout
  [data-slice-type="social-icons"]
  .use-icon {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]
  .use-icon {
  fill: #222;
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]
  svg.social {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]
  .use-mask {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper:hover {
  background-color: #fff;
  border-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-border
  [data-slice-type="social-icons"]
  .icon-wrapper:hover
  .use-icon {
  fill: #222;
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .icon-wrapper
  .use-icon {
  fill: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-regular
  [data-slice-type="social-icons"]:hover
  .icon-wrapper:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .icon-wrapper
  .use-mask {
  fill: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-knockout
  [data-slice-type="social-icons"]:hover
  .icon-wrapper:hover
  .use-mask {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
  background-color: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper
  svg.social {
  background-color: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper
  .use-mask {
  fill: rgba(34, 34, 34, 0);
  background-color: rgba(34, 34, 34, 0);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper:hover
  .use-icon {
  fill: #222;
  background-color: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper:hover
  svg.social {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .social-icons-style-solid
  [data-slice-type="social-icons"]:hover
  .icon-wrapper:hover
  .use-mask {
  fill: rgba(34, 34, 34, 0);
  background-color: rgba(34, 34, 34, 0);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .track-font {
  font-family: "freight-sans-pro";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 1px;
  line-height: 1.4em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-title
  a {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-title
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-time {
  font-family: "freight-sans-pro";
  line-height: 1.4em;
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 16px;
  text-transform: none;
  letter-spacing: 1px;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  padding-bottom: 29px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar
  .bar.bg,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar
  .bar.play-bar {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="album"]
  .track-progress-bar
  .bar.bg {
  background-color: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-solid
  [data-slice-type="album"]
  .icon-wrapper
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-solid
  [data-slice-type="album"]:hover
  .icon-wrapper
  .use-background {
  fill: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-solid
  [data-slice-type="album"]:hover
  .icon-wrapper
  .use-icon {
  fill: rgba(34, 34, 34, 0.4);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-solid
  [data-slice-type="album"]:hover
  .icon-wrapper:hover
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-solid
  [data-slice-type="album"]:hover
  .icon-wrapper:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper {
  border: 2px solid #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:hover {
  background-color: #fff;
  border-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-border
  [data-slice-type="album"]
  .icon-wrapper
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-regular
  [data-slice-type="album"]
  .icon-wrapper
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-regular
  [data-slice-type="album"]:hover
  .icon-wrapper
  .use-icon {
  fill: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-regular
  [data-slice-type="album"]:hover
  .icon-wrapper:hover
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-knockout
  [data-slice-type="album"]
  .icon-wrapper
  .use-icon {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-knockout
  [data-slice-type="album"]:hover
  .icon-wrapper
  .use-background {
  fill: rgba(255, 255, 255, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .audio-player-icons-style-knockout
  [data-slice-type="album"]:hover
  .icon-wrapper:hover
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-album-minimal
  .sqs-slice-album-playlist
  .track-title {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .show-track-title
  .sqs-album-minimal
  .sqs-slice-album-playlist
  .track-title {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper:hover
  .use-background {
  fill: rgba(255, 255, 255, 0.8);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-solid
  .sqs-slice-play-button.icon-wrapper:hover
  .use-icon {
  fill: rgba(34, 34, 34, 0.8);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper {
  border: 2px solid #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper:hover {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper:hover
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-border
  .sqs-slice-play-button.icon-wrapper:hover
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper
  .use-icon {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-regular
  .sqs-slice-play-button.icon-wrapper:hover
  .use-icon {
  fill: rgba(255, 255, 255, 0.8);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper
  .use-background {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper
  .use-icon {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .video-icon-style-knockout
  .sqs-slice-play-button.icon-wrapper:hover
  .use-background {
  fill: rgba(255, 255, 255, 0.8);
}
.map-style-minimal-light
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"] {
  background-color: #f2f2f2 !important;
}
.map-style-minimal-dark
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"] {
  background-color: #0f0f0f !important;
}
.map-style-minimal-blue
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="map"] {
  background-color: #ccdee9 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .tweet-display-name-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .twitter-meta-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .tweet-body-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-size: 130px;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: -2px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-display-name
  a {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-style: normal;
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-handle
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-timestamp
  a {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-style: normal;
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-body {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 130px;
  line-height: 1em;
  text-transform: none;
  letter-spacing: -2px;
  font-weight: 500;
  font-style: normal;
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-body
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-body
  a:visited {
  color: #fff;
  text-decoration: none;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  transition: border-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-body
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-body
  a:focus {
  color: #fff;
  border-bottom-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-timestamp
  .icon-wrapper {
  display: inline-block;
  top: -0.06em;
  position: relative;
  width: 2.8em;
  height: 2.8em;
  margin-right: -0.5em;
  margin-left: -0.7em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="twitter"]:not([data-compound-type])
  .tweet-timestamp
  .icon-wrapper
  svg {
  fill: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper {
  border-radius: 0 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-regular
  [data-slice-type="lock"]
  .use-icon {
  fill: #a2a2a2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-regular
  [data-slice-type="lock"]
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-regular
  [data-slice-type="lock"]
  .use-mask {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper
  > div {
  transform: scale(2);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-knockout
  [data-slice-type="lock"]
  .use-icon {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-knockout
  [data-slice-type="lock"]
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-knockout
  [data-slice-type="lock"]
  .use-mask {
  fill: #a2a2a2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-border
  [data-slice-type="lock"]
  .icon-wrapper {
  border: 2px solid;
  box-sizing: border-box;
  border-color: #a2a2a2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-border
  [data-slice-type="lock"]
  .use-icon {
  fill: #a2a2a2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-border
  [data-slice-type="lock"]
  .use-background {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-border
  [data-slice-type="lock"]
  .use-mask {
  fill: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-solid
  [data-slice-type="lock"]
  .use-icon {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-solid
  [data-slice-type="lock"]
  .use-background {
  fill: #222;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-style-solid
  [data-slice-type="lock"]
  .use-mask {
  fill: #a2a2a2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-shape-circle
  [data-slice-type="lock"]
  .icon-wrapper {
  border-radius: 50%;
  overflow: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-shape-rounded
  [data-slice-type="lock"]
  .icon-wrapper {
  border-radius: 15%;
  overflow: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-shape-square
  [data-slice-type="lock"]
  .icon-wrapper {
  border-radius: 0%;
  overflow: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-extra-small
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 48px;
  height: 48px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-extra-small.lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 24px;
  height: 24px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-extra-small.lock-style-border
  .icon-wrapper {
  border-width: 1px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-small
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 64px;
  height: 64px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-small.lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 32px;
  height: 32px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-small.lock-style-border
  .icon-wrapper {
  border-width: 2px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-medium
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 96px;
  height: 96px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-medium.lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 40px;
  height: 40px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-medium.lock-style-border
  .icon-wrapper {
  border-width: 3px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-large
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 128px;
  height: 128px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-large.lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 48px;
  height: 48px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-large.lock-style-border
  .icon-wrapper {
  border-width: 4px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-extra-large
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 144px;
  height: 144px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-extra-large.lock-style-regular
  [data-slice-type="lock"]
  .icon-wrapper {
  width: 56px;
  height: 56px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .lock-size-extra-large.lock-style-border
  .icon-wrapper {
  border-width: 5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .password-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.6px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  input[type="password"] {
  background-color: #fff;
  color: #a3a3a3;
  padding: 14px 48px 14px 15px;
  height: 44px;
  transition: padding-right 0.1s ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  input[type="password"]::-webkit-input-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  input[type="password"]:-moz-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  input[type="password"]::-moz-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  input[type="password"]:-ms-input-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  .arrow-icon
  .icon-wrapper {
  fill: #a3a3a3;
  opacity: 0.2;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  .arrow-icon:hover
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  .arrow-icon:focus
  .icon-wrapper {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]
  .error-message {
  top: 16px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"].show-error
  .error-message {
  top: 44px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-rectangle
  [data-slice-type="password"]:not(.show-arrow)
  input[type="password"] {
  padding-right: 15px !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  input[type="password"] {
  background-color: transparent;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding: 9px 34px 11px 0;
  height: 38px;
  transition: padding-right 0.1s ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  input[type="password"]::-webkit-input-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  input[type="password"]:-moz-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  input[type="password"]::-moz-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  input[type="password"]:-ms-input-placeholder {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  .arrow-icon {
  top: -4px;
  right: -14px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  .arrow-icon
  .icon-wrapper {
  fill: #fff;
  opacity: 0.5;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  .arrow-icon:hover
  .icon-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  .arrow-icon:focus
  .icon-wrapper {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]
  .error-message {
  top: 38px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .password-style-underlined
  [data-slice-type="password"]:not(.show-arrow)
  input[type="password"] {
  padding-right: 0 !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  input[type="password"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="password"]
  .error-message {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  letter-spacing: 0.6px;
  font-weight: normal;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .temp-lock-inject-container {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 26px;
  line-height: 1.3em;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.02em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide {
  position: fixed;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .layer-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .layer-front
  .actions {
  width: 100%;
  max-width: 550px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer-content {
  width: 100%;
  height: auto !important;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(121, 121, 121, 0.17);
  outline: 1px solid rgba(121, 121, 121, 0.1);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer-content
  .group-copy {
  overflow: hidden;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-slide-layout-id="overlay-full-center-01"]
  .sqs-slide-layer-content,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-slide-layout-id="overlay-full-center-02"]
  .sqs-slide-layer-content {
  box-shadow: none;
  outline: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer.layer-front.scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer.layer-front.scroll
  > .sqs-slide-layer-content {
  height: auto;
  padding-bottom: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer.layer-front.scroll
  .group-copy {
  margin-bottom: 60px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-slice-type="buttons"]
  li
  a,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  li
  a {
  padding: 1.4em 2.5em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-style-outline
  [data-slice-type="buttons"]
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-style-outline
  [data-slice-type="sso-options"]
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-style-outline
  .sqs-slice-custom-form
  a:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].button-style-outline
  .form-wrapper
  input[type="submit"]:hover {
  color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer-content {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .captcha-container-wrapper
  .captcha-container {
  width: auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .captcha-container-wrapper
  .captcha-container.align-center
  > div {
  margin-left: auto;
  margin-right: auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .captcha-container-wrapper
  .captcha-container.align-right
  > div {
  margin-left: auto;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  .input-wrapper:not(.hidden) {
  display: inline-flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  min-width: 95%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  .input-wrapper:not(.hidden)
  .field-error {
  display: block;
  position: absolute;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  font: 14px Helvetica, sans-serif;
  color: #f23d3d;
  background-color: #fed9db;
  transform: translatey(calc(-100% - 3px));
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  .form-item {
  flex: 1 1 50%;
  display: flex !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  .form-item
  label {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  .form-item
  input[type="text"] {
  width: 100%;
  min-width: 200px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  button[type="submit"] {
  flex: 0 0 auto;
}
.sqs-slide-container.button-style-solid
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  button[type="submit"] {
  border: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions:not(.stacked)
  .field-error {
  display: block;
  position: absolute;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  font: 14px Helvetica, sans-serif;
  color: #f23d3d;
  background-color: #fed9db;
  margin: 0 3%;
  bottom: 0;
  transform: translatey(calc(3px + 100%));
  width: 94%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  .sqs-slice-newsletter-content,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  .sqs-slice-newsletter-content {
  display: block !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  .input-wrapper,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  .input-wrapper {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  .input-wrapper
  .form-item,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  .input-wrapper
  .form-item,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  .input-wrapper
  button,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  .input-wrapper
  button,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  .input-wrapper
  input[type="text"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  .input-wrapper
  input[type="text"] {
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  input[type="text"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  input[type="text"] {
  margin-right: 0 !important;
  margin-bottom: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-stacked
  button,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
  .actions.stacked
  button {
  min-height: 44px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style
  .field-error {
  display: block;
  position: absolute;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  font: 14px Helvetica, sans-serif;
  color: #f23d3d;
  background-color: #fed9db;
  margin: 0;
  top: 0;
  transform: translatey(calc(-3px - 100%));
  width: 100%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .newsletter-font {
  font-size: 14px;
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.6px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .disclaimer-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .error-message,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-submission-text {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 26px;
  line-height: 1.3em;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.02em;
  color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-disclaimer-text
  p {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-disclaimer-text
  p
  a {
  text-decoration: underline;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-rectangle
  [data-compound-type="popup-overlay-action"]
  input[type="text"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-outline
  [data-compound-type="popup-overlay-action"]
  input[type="text"] {
  background-color: #fff;
  color: #000;
  padding: 14px 15px;
  box-sizing: border-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-rectangle
  [data-compound-type="popup-overlay-action"]
  input[type="text"]::-webkit-input-placeholder,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-outline
  [data-compound-type="popup-overlay-action"]
  input[type="text"]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-rectangle
  [data-compound-type="popup-overlay-action"]
  input[type="text"]:-moz-placeholder,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-outline
  [data-compound-type="popup-overlay-action"]
  input[type="text"]:-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-rectangle
  [data-compound-type="popup-overlay-action"]
  input[type="text"]::-moz-placeholder,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-outline
  [data-compound-type="popup-overlay-action"]
  input[type="text"]::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-rectangle
  [data-compound-type="popup-overlay-action"]
  input[type="text"]:-ms-input-placeholder,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-outline
  [data-compound-type="popup-overlay-action"]
  input[type="text"]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-rectangle
  [data-compound-type="popup-overlay-action"]
  input[type="text"] {
  margin-right: 20px;
  border-style: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-outline
  [data-compound-type="popup-overlay-action"]
  input[type="text"] {
  margin-right: 20px;
  border: 1px solid #bfbfbf;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-underlined
  [data-compound-type="popup-overlay-action"]
  input[type="text"] {
  margin-right: 20px;
  background-color: transparent;
  color: #000;
  border: none;
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 7px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-underlined
  [data-compound-type="popup-overlay-action"]
  input[type="text"]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-underlined
  [data-compound-type="popup-overlay-action"]
  input[type="text"]:-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-underlined
  [data-compound-type="popup-overlay-action"]
  input[type="text"]::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-style-underlined
  [data-compound-type="popup-overlay-action"]
  input[type="text"]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  input[type="text"],
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .error-message {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: normal;
  letter-spacing: 0.6px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.015em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-submission-text.hidden,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-submission-html.hidden {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-submission-text.hidden
  p.hidden,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .form-submission-html.hidden
  p.hidden {
  display: block;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-compound-type="popup-overlay-action"]
  .input-wrapper.hidden {
  display: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .form-submission-text:focus:not(:focus-visible),
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .form-submission-html:focus:not(:focus-visible),
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .field-error:focus:not(:focus-visible) {
  outline: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  :focus {
  outline-color: #171717;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  :focus:not(:focus-visible) {
  outline-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-popup-overlay-close {
  width: auto;
  height: auto;
  padding: 0.4em 0.7em;
  z-index: 999;
  font-family: Helvetica, Arial, sans-serif;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-close-button-style-icon
  .sqs-popup-overlay-close:before {
  content: "×";
  font-size: 22px;
  line-height: 1em;
  border: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-close-button-style-text
  .sqs-popup-overlay-close:before {
  content: "Close";
  font-size: 12px;
  letter-spacing: 0.05em;
  line-height: 1.2em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-popup-overlay-close {
  color: #9c9c9c;
  border-bottom-style: none;
  transition: color 0.1s ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-popup-overlay-close:hover,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-popup-overlay-close:focus {
  color: #757575;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-popup-overlay-close:before {
  color: inherit;
  border-bottom: 1px solid #9c9c9c;
  display: inline-block;
  cursor: pointer;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-close-button-location-inside
  .sqs-popup-overlay-close {
  position: absolute;
  top: 3px;
  right: 3px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-close-button-location-corner
  .sqs-popup-overlay-close {
  position: fixed;
  top: 20px;
  right: 20px;
  opacity: 0;
  transition: opacity 0.25s 0.5s ease-in;
  transform: scale3d(1, 1, 1);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-animation-ready
  .sqs-popup-overlay-close {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-fade-in
  .sqs-slide-layer-content {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-fade-in
  .sqs-slide.sqs-slide-animation-ready
  .sqs-slide-layer-content {
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-slide-in
  .sqs-slide.sqs-slide-animation-ready
  .sqs-slide-layer-content {
  transition: transform 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-slide-in
  .sqs-slide.sqs-slide-ready.sqs-slide-animation-ready
  .sqs-slide-layer-content {
  transform: none;
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-slide-in
  .sqs-slide-ready
  .sqs-slide-layer-content {
  transform: translatey(5%);
  opacity: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-scale-up
  .sqs-slide-layer-content {
  transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: scale3d(0.9, 0.9, 1);
  opacity: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-load-animation-scale-up
  .sqs-slide.sqs-slide-animation-ready
  .sqs-slide-layer-content {
  transform: none;
  opacity: 1;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-left
  .sqs-slide-layer-content
  .group-copy {
  text-align: left;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-left
  [data-slice-type="body"] {
  margin: 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
  .sqs-slide-layer-content
  .group-copy {
  text-align: center;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
  .actions {
  margin: 30px auto 0;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .sqs-slide-layer-content {
  max-width: 300px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  .group-copy {
  padding: 50px 40px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"]
  [data-slice-type="heading"]:not([data-content-empty="true"])
  h1#sqs-slash-page-header {
  margin-bottom: 20px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-left
  .actions,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
  .actions,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-left
  .form-disclaimer-text,
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
  .form-disclaimer-text {
  margin-top: 25px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].enable-overlay-content-border
  .sqs-slide-layer-content {
  border-style: solid;
  border-width: 1px;
  box-sizing: content-box;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sqs-slide-container[data-slide-type="popup-overlay"].enable-overlay-content-border
  .sqs-slide-layer-content {
  border-color: #5e5e5e;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .overlay-mobile-heading-font {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .overlay-mobile-body-font {
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .overlay-mobile-button-font {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1px;
}
@media all and (min-width: 1px) and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .sqs-slide {
    background-color: transparent !important;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    .group-copy {
    background-color: #fff;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="heading"]:not([data-compound-type])
    h1#sqs-slash-page-header {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 1em;
    font-size: 21px !important;
    color: #000;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="body"] {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: #000;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="body"]
    p {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: #000;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="body"]
    p
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="body"]
    p
    a:visited {
    color: #000;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="body"]
    p
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="body"]
    p
    a:focus {
    color: #000;
    border-bottom-color: #000;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-compound-type="popup-overlay-action"]
    .error-message,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-compound-type="popup-overlay-action"]
    .form-submission-text,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-compound-type="popup-overlay-action"]
    input[type="text"],
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-compound-type="popup-overlay-action"]
    .error-message,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="buttons"]
    ul,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-slice-type="sso-options"]
    ul,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-compound-type="popup-overlay-action"]
    button[type="submit"] {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled
    [data-compound-type="popup-overlay-action"]
    input[type="text"] {
    font-size: 16px !important;
    padding: 0.8em !important;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-solid
    [data-slice-type="buttons"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-solid
    [data-slice-type="sso-options"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-solid
    [data-compound-type="popup-overlay-action"]
    button[type="submit"] {
    background: #222;
    color: #fff;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-slice-type="buttons"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-outline
    [data-slice-type="sso-options"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-compound-type="popup-overlay-action"]
    button[type="submit"] {
    color: #222;
    border: 2px solid #222;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-slice-type="buttons"]
    li
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-outline
    [data-slice-type="sso-options"]
    li
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-compound-type="popup-overlay-action"]
    button[type="submit"]:hover {
    color: #efefef;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-slice-type="buttons"]
    li
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-outline
    [data-slice-type="sso-options"]
    li
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-compound-type="popup-overlay-action"]
    button[type="submit"]:hover {
    background-color: #222;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-slice-type="buttons"]
    li
    a:hover
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-outline
    [data-slice-type="sso-options"]
    li
    a:hover
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-outline
    [data-compound-type="popup-overlay-action"]
    button[type="submit"]:hover
    a {
    color: #efefef;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-raised
    [data-slice-type="buttons"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-raised
    [data-slice-type="sso-options"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-raised
    [data-compound-type="popup-overlay-action"]
    button[type="submit"] {
    background-color: #222;
    color: #fff;
    box-shadow: 0px 0.2em 0px 0px #262626;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-raised
    [data-slice-type="buttons"]
    li
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.sso-button-style-raised
    [data-slice-type="sso-options"]
    li
    a:hover,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-mobile-styles-enabled.button-style-raised
    [data-compound-type="popup-overlay-action"]
    button[type="submit"]:hover {
    background-color: #2a2a2a;
  }
}
@media all and (min-width: 1px) and (max-width: 600px) {
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .sqs-slide {
    position: absolute;
    overflow-y: auto;
    top: auto;
    bottom: 0;
    height: auto;
    max-height: 100%;
    border-top: 1px solid rgba(121, 121, 121, 0.1);
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .layer-back {
    display: none;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .layer-front {
    position: static;
    display: block;
    height: auto;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .sqs-slide-layer-content {
    max-width: 100%;
    width: 100% !important;
    margin: 0;
    box-shadow: none;
    outline: none;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .sqs-popup-overlay-close:before {
    color: #ababab;
    display: inline-block;
    cursor: pointer;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .group-copy {
    box-sizing: border-box;
    padding: 25px !important;
    width: 100%;
    float: none;
    background-color: #fff;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
    .group-actions,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-left
    .group-actions,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
    .actions,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-left
    .actions {
    margin-top: 20px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .sqs-slide-layer.layer-front
    .group-content
    .group-text {
    padding-right: 0;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    .sqs-slice-group.left-right-flex {
    display: block;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    [data-slice-type="heading"][data-compound-type="logo"]:not(
      [data-content-empty="true"]
    )
    h1 {
    margin-bottom: 1em;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    [data-slice-type="buttons"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    [data-slice-type="sso-options"]
    li
    a,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    [data-compound-type="popup-overlay-action"]
    button[type="submit"] {
    padding: 1em;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    [data-slice-type="gallery"] {
    display: none;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    button[type="submit"] {
    flex: none;
    display: block;
    width: 100%;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .input-wrapper {
    display: block;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .form-item {
    flex: none;
    display: block !important;
    width: 100%;
    margin-bottom: 0;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline.newsletter-style-outline
    [data-compound-type="popup-overlay-action"]
    input[type="text"] {
    margin-right: 0;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline.newsletter-style-rectangle
    [data-compound-type="popup-overlay-action"]
    input[type="text"],
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline.newsletter-style-outline
    [data-compound-type="popup-overlay-action"]
    input[type="text"] {
    padding: 1em;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
    .sqs-slide-layer-content
    .group-copy,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-right
    .sqs-slide-layer-content
    .group-copy {
    text-align: left;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-center
    [data-compound-type="popup-overlay-action"][data-slice-type="newsletter"]
    .input-wrapper,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-alignment-right
    [data-compound-type="popup-overlay-action"][data-slice-type="newsletter"]
    .input-wrapper {
    padding: 0;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].enable-overlay-content-border
    .sqs-slide-layer-content {
    box-sizing: border-box;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].overlay-close-button-location-corner
    .sqs-popup-overlay-close {
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"]
    [data-slice-type="heading"]:not([data-compound-type])
    h1#sqs-slash-page-header {
    font-size: 24px;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .sqs-slice-newsletter-content {
    display: block !important;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions.stacked
    .input-wrapper:not(.hidden),
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions:not(.stacked)
    .input-wrapper:not(.hidden) {
    display: block !important;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions.stacked
    .input-wrapper:not(.hidden)
    .form-item,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions:not(.stacked)
    .input-wrapper:not(.hidden)
    .form-item,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions.stacked
    .input-wrapper:not(.hidden)
    button,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions:not(.stacked)
    .input-wrapper:not(.hidden)
    button,
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions.stacked
    .input-wrapper:not(.hidden)
    input[type="text"],
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    .actions:not(.stacked)
    .input-wrapper:not(.hidden)
    input[type="text"] {
    width: 100%;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    input[type="text"] {
    margin-right: 0 !important;
    margin-bottom: 1em;
  }
  .sqs-slide-wrapper[data-slide-type="popup-overlay"]
    .sqs-slide-container[data-slide-type="popup-overlay"].newsletter-layout-style-inline
    button {
    min-height: 44px;
  }
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sso-button-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slice-type="sso-options"]
  ul {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-solid
  [data-slice-type="sso-options"]
  a {
  background: #fff;
  color: #222;
  border-style: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-outline
  [data-slice-type="sso-options"]
  a {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  transition: color 170ms ease-in-out, border-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-outline
  [data-slice-type="sso-options"]
  a:hover {
  color: #181818;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-outline
  ~ .sqs-modal-lightbox
  .form-wrapper
  input[type="submit"] {
  background-color: transparent;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-outline
  [data-slice-type="sso-options"]
  a {
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-outline
  [data-slice-type="sso-options"]
  a:hover {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-outline
  [data-slice-type="sso-options"]
  a:hover
  a {
  border-color: transparent;
  color: #181818;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-raised
  [data-slice-type="sso-options"]
  a {
  background-color: #fff;
  color: #222;
  border-style: none;
  box-shadow: 0px 0.2em 0px 0px #ebebeb;
  transition: background-color 170ms ease-in-out;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .sso-button-style-raised
  [data-slice-type="sso-options"]
  a:hover {
  background-color: #fff;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slide-layout-id="overlay-corner-card-01"]
  .sqs-slide-layer-content {
  margin: 4%;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  [data-slide-layout-id="overlay-corner-card-01"]
  .layer-front {
  align-items: flex-end !important;
  justify-content: flex-end !important;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .branding-font {
  font-family: "'Helvetica Neue', Helvetica, Arial, sans-serif";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0.02em;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .heading-font {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0px;
  line-height: 1.1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .body-font {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: none;
  letter-spacing: 0.02em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .countdown-value-font {
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 150px;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .countdown-numeric-label-font {
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-weight: 300;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .navigation-font {
  font-family: "'Helvetica Neue', Helvetica, Arial, sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .button-font {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .track-font {
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 16px;
  text-transform: none;
  letter-spacing: 1px;
  font-weight: 500;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .tweet-display-name-font {
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .twitter-meta-font {
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .tweet-body-font {
  font-family: "" Helvetica Neue ", Helvetica, Arial, sans-serif";
  font-size: 130px;
  line-height: 1em;
  text-transform: none;
  letter-spacing: -2px;
  font-weight: 500;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .password-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  letter-spacing: 0.6px;
  font-weight: normal;
  font-style: normal;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .newsletter-font {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.015em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .disclaimer-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.5px;
  text-transform: none;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .overlay-mobile-heading-font {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1em;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .overlay-mobile-body-font {
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.5px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"]
  .overlay-mobile-button-font {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1px;
}
.sqs-slide-wrapper[data-slide-type="popup-overlay"] .sso-button-font {
  font-family: "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
</style>