import { createApp } from "vue";
import i18n from '@/i18n'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/common.css";
import "@/assets/css/footer.css";
import '@/config/index'
import Vue3Esign from 'vue3-esign'

createApp(App).use(store).use(router).use(i18n).use(Vue3Esign).mount("#app");
