
export const Env = {
  mode: 'development',
  apiUrl: 'https://usa7-1-1626.gusm.top',
  webUrl: 'https://usa7-1.gusm.top'
}
if (process.env.NODE_ENV === 'production') {
  Env.mode = 'production'
  Env.apiUrl = 'https://usa7-1prod9526.wealthfronty.top'
  Env.webUrl = 'https://www.equiassets.top'
}
